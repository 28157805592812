export enum PlanIdType {
  LIGHT = 'agent_360_light',
  REGULAR = 'agent_360',
  PRO = 'agent_360_pro',
}

export const PlanGrades =  {
  'agent_360_light': 0,
  'agent_360': 1,
  'agent_360_pro': 2,
} as const;

// import { PlanIdType } from '@skygroup/shared/constants/agency';

export enum RegStatusType {
  STATUS_PLAN = 'plan_selection',
  STATUS_AGENCY_INFO = 'agency_info',
  STATUS_PAYMENT = 'payment',
  STATUS_CONTRACT_TO_SIGN = 'contract_to_sign',
  STATUS_COMPLETED = 'completed',
}
