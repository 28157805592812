const translationsEn = {
    "status": "success",
    "data": {
        "lang": "en_US",
        "app": "hotel",
        "translation": {
            "crud": {
                "updatedSuccessfully": 'Updated Successfully',
                "validationFailed" : "Validation failed",
                "fieldRequired": "Mandatory field",
                "operationError" : "Error occurred",
                "emptyList" : "No Data Found",
                "failToLoad" : "Error occurred on loading",
                "modelNotFound" : "Not Found",
            },
            "updateCard": {
                "payment_failed_please_update_card": "Payment failed - please update card",
                "open_the_payment_form": "Open the payment form",
            },
            "upgradePlan": {
                "general": {
                    "upgrade_plan": 'Upgrade Plan',
                    "upgrade_plan_to_regular": 'Upgrade To Regular Plan',
                    "upgrade_plan_to_pro": 'Upgrade To PRO Plan',
                  },
                "regular": {
                    "title" : "Upgrade now and get access to Agent 360 products!",
                    "benefits_title_text": "You are currently connected to the Agent 360 Light package, if you upgrade you can get it",
                    "text_1": "Sky 360 Wholesale Products",
                    "text_2": "Hotels for all destinations worldwide",
                    "text_3": "Regular and Low-Cost Flights",
                    "text_4": "Attractions worldwide",
                    "button_text": "Upgrade now to Agent 360!",
                },
                "pro": {
                    "title" : "Upgrade now and get a website for your business with full branding!",
                    "benefits_title_text": "In the SKY 360 Pro package you can enjoy",
                    "text_1": "A website with your unique domain",
                    "text_2": "Business branding - logo and site colors",
                    "text_3": "Promotion of packages and flights on the website by destinations",
                    "text_4": "All bookings made will belong to your agency!",
                    "button_text": "Upgrade now to Pro!"
                },
            },
            "contactUs": {
                "requestForQuotation":'Request for quotation',
                "problemWithExistingOrder":"Problem with existing order",
                "customerService":"Customer service",
                "other":"Other",
                "messageReceivedSuccessfully":"Message received successfully",
                "email": "Email",
                "phone": "Phone",
                "fullName": "Full Name",
                "writeUsMessage": "Write us a message",
                "send": "Send",
                "phoneRequired": "Please enter a phone number",
                "emailRequired": "Please enter an email address",
                "fullNameRequired": "Please enter a full name",
                "messageRequired": "Please enter a message",
                "aboutUs": "About Us"
            },
            "cart": {
                "addToCart": "Add to Cart",
            },
            "customerSupport": {
                "customerService": "Customer Service",
                "sendMessageAndGetNotified": "Send us a message and the auctioneer will get back to you as soon as possible",
                "callUs": "Call us: ",
            },
            "roomInfo": {
                "info": "Room Description",
                "viewAllThePhotos": "View all the photos",
                "roomSize": "Room Size (sqm)",
                "peopleInTheRoom": "People in the Room",
                "doubleBed": "Double Bed"
            },
            "conceptsList":{
                "conceptsListTitle": "Concepts List Title",
                "includesActivities": "Gift attractions",
                "includesActivity": "Includes a gift attraction",
                "includeInPackage": "Includes In Package",
                "transfer": "Transfer",
                "includesTransfer": "Includes Transfer",
                "transferContent": "HOS transfers at the price of the package",
                "baseBoard": "Base Board",
                "autoApprove": "Auto Approve",
                "airways": "Airways",
                "flightFrom": "Flight From",
                "flightBack": "Flight Back",
                "nights": "Nights",
                "toOrder": "To Order",
                "noPackagesFound": "No Package Found",
                "moreDeals": "More Deals",
                "pricePerPerson":"Price Per Person",
            },
            "viewFlight":{
                "show_tickets_with_different_fare_combinations":"Show tickets with different fare combinations",
                "ticketFare":"Fare",
                "ticketInfo":"Ticket info",
                "termsAndConditions": "Terms and Conditions",
                "ifTicketIncludeSeat": " If ticket include seat - client will need to choose it from the airline website",
                "inCaseOfChange": "In case of change - client will need to pay extra money if the updated ticket is higher, also there is additional service fee based on the site Terms & Services",
                "inCaseOfCancellation": "In case of cancellation - it will be additional service fee based on the site Terms & Services",
                "error4305Title": "Oops, the flight is fully booked",
                "error4305Content": "We apologize, but it seems that the seats for this offer are sold out, and we cannot proceed with the purchase. You are being redirected to the main page.",
                "TC": "TC",
                "flights": "Flights",
                "title": "Selected flight, verify details and proceed to payment",
                "addBaggage": "Add baggage",
                "closeAddBaggage": "Hide options",
                "addTrolly": "Add trolley",
                "somethingWetWrong": "Something went wrong",
                "suitcasePrice": "Suitcase price",
                "trollyPrice": "Trolley price",
                "totalPerPassenger": "Total per passenger",
                "updatePassengers": "Update number of passengers",
                "search": "Search",
                "seating": "Seating",
                "cancelation": "Ticket cancellation",
                "meal": "Meals",
                "trolly": "Trolly",
                "suitcase": "Suitcase",
                "changeFlight": "Change ticket",
                "extension": "Extension",
                "totalToBePaid": "Total to be paid",
                "choose": "Choose",
                "checkAmenities":'Check amenities',
                "description":"Description",
                "amenityType":"Amenity type",
                "flightFromAmenities":"Flight from amenities",
                "flightBackAmenities":"Flight back amenities",
                "BAGGAGE": "Baggage",
                "2 CHECKED BAGS UP TO 32KG EACH": "2 Checked bags up to 32kg each",
                "2 CABIN BAGS UP TO 8KG": "2 Cabin bags up to 8kg",
                "BRANDED_FARES": "Branded fares",
                "STANDARD SEAT RESERVATION": "Standard seat reservation",
                "MILEAGE_ACCRUAL": "Mileage accrual",
                "MEAL": "Meal",
                "SNACK": "Snack",
                "COMPLIMENTARY FOOD AND BEV": "Complimentary food and beverages",
                "ENTERTAINMENT": "Entertainment",
                "MAGAZINES / NEWSPAPER": "Magazines / Newspapers",
                "LOUNGE": "Lounge",
                "LOUNGE ACCESS": "Lounge access",
                "TRAVEL_SERVICES": "Travel services",
                "PRIORITY BAGGAGE": "Priority baggage",
                "PRIORITY CHECK IN": "Priority check-in",
                "PRIORITY SECURITY": "Priority security",
                "PRIORITY BOARDING": "Priority boarding",
                "IN FLIGHT ENTERTAINMENT": "In-flight entertainment",
                "PRE_RESERVED_SEAT": "Pre-reserved seat",
                "PRIVACY SEAT BUSINESS CLASS": "Privacy seat business class",
                "CHANGE BEFORE DEPARTURE": "Change before departure",
                "CHANGE AFTER DEPARTURE": "Change after departure",
                "REFUND BEFORE DEPARTURE": "Refund before departure",
                "REFUND AFTER DEPARTURE": "Refund after departure",
                "allFares":"All fares",
                "fareChangedSuccessfully":"Fare changed successfully",
                "openFares":"Open fares",
                "selectedFare":"Selected Fare",
                "fullInfo":"Full info",
                "MILEAGE ACCRUAL": "Mileage Accrual",
            },
            "room": {
                "roomSize": "Room Size",
                "bedType": "Bed Type",
                "breakfastIncluded": "Breakfast Included",
                "noAvailabilityOnSelectedDates": "No availability for this room on the selected dates"
            },
            "promotion": {
                "modelNotFound": "Page not found",
                "failToLoad": "Failed to load the page!"
            },
            "searchDatePicker": {
                "choose_packages_according_to": "Choose packages according to",
                "tech_stops": "tech stops",
                "freePlanFlightSearchFooterText": "Free plan allows to search Charter flights only!  If you want GDS flights",
                "clickHere": "Click Here",
                "approveSelection": "Approve Selection",
                "attractions":"Attractions",
                "attractions_type":"Types of Attractions",
                "room":"Room",
                'addRoom':"Add Room",
                "removeRoom": "Remove Room",
                "indirectFlight":"Indirect Flight",
                "directFlightLabel": "Direct Flight",
                "city": "City",
                "hotel": "Hotel",
                "searchForDestination": "Search For Destinations",
                "searchByDestinationAndHotel": "Search By Destinations And Hotels",
                "date": "Date",
                "noResultsForThisDate": "No packages found for this date",
                "searchDestination": "Search destination",
                "pleaseSelectDestination": "Please select a destination",
                "airlineInformation": "Airline information",
                "airlineCode": "Code",
                "title": "Book your dream vacation",
                "hotelOnly": "Search vacation for dates",
                "hotelWithFlight": "Search vacation including flight",
                "oneWay": "One way",
                "roundTrip": "Round trip",
                "selectDate": "Dates selection",
                "startDate": "Start date",
                "endDate": "End date",
                "flights": "Search flights",
                "packages": "Vacation packages",
                "hotels": "Hotels abroad",
                "israelPackage": "Vacation in Israel",
                "specialPackage": "Special packages",
                "passengers": "Passengers",
                "adults": "Adults",
                "adult": "Adult",
                "child": "Child",
                "children": "Children",
                "infant": "Infant",
                "infants": "Infants",
                "infantsWarningMessage": "Infant must be under two years old on the outbound and return flight",
                "select": "Select",
                "noResults": "No results",
                "checkout": "Book now",
                "regularPrice": "Regular price",
                "from": "From",
                "purchase": "Purchase",
                "selectNewFlight": "Select a new flight",
                "search": "Search",
                "rooms": "Rooms",
                "composition": "Composition",
                "chooseComposition": "Choose Composition",
                "numberOfGuests": "Number of Guests",
                "checkOut": "Check Out",
                "checkIn": "Check In",
                "whatVacation": "What kind of vacation",
                "youWant": "do you want",
                "vacationFriendsWith": "Vacation with friends",
                "familyVacation": "Family Vacation",
                "romanticVacation": "Romantic Vacation",
                "notAvailable": "Not Available",
                "roomAvailableDuringTheWeek": "Room availability during the week",
                "followingDates": "Following Dates",
                "vacation": "Vacation",
                "abroad": "Abroad",
                "inIsrael": "In the country",
                "destination": "All destinations",
                "chooseDestination": "Choose destinations",
                "findDreamVacation": "Find your perfect vacation with a click.",
                "searchResTitle": "Choose the right package for you",
                "directFlight": "Direct Flight",
                "stops": "stops",
                "checkHotel": "Check Hotel",
                "noRating": "No Rating",
                "toOrder": "To Order",
                "selectAgain": "Select Again",
                "selectFlight": "Select A Flight",
                "reselectFlight": "Reselect Flights",
                "flightSelectedSuccessfully": "Flight Selected Successfully",

                "dynamicFlight": "Dynamic Flight",
                "bundle": "Bundled Flight",
                "freeCancelation": "Free Cancellation",
                "partially_operated_by_airlines": "Partially operated by airlines",
                "connect_in_airport": "Connect in airport",
                "arrives": "Arrives",
                "journey_duration": "Journey Duration",
                "short_wait": "Short Wait",
                "long_wait": "Long Wait",
                "flight_number": "Flight Number",

                "stop_1": "One Stop",
                "activeFlight": "Flight Activated",
                "flightSearch": "Flight Search",
                "nights": "Nights",
                "until": "Until Date",
                "untilYouSearchForFlightDate": "Until Flight Search Date",
                "destinationFrom": "Departure",
                "packageSearch": "Package Search",
                "destinationTo": "Arrival",
                "selectDestination": "Destination",
                "trollyIsIncluded": "Trolley Included",
                "trollyIsNotIncluded": "Trolley Not Included",
                "suitcaseIsIncluded": "Suitcase Included",
                "suitcaseIsNotIncluded": "Suitcase Not Included",
                "operated_by_airlines": "Operated by",
            },
            "specialDeal": {
                "closeTheDeal": "Close the deal ›",
                "endsIn": "Ends in",
                "specialDealForTheWeekend": "Special deal for the weekend",
                "from": "From date",
                "until": "Until date"
            },
            "footer": {
                "backToTop": "Back to top",
                "allRightsReserved": "© All rights reserved. Do not copy content from the site without permission.",
                "developBy": "Developed by SKY 360 Tech.",
                "thePurchaseOnTheWebsiteIsSecure": "The purchase on the site is secure.",
                "talkToUs": "How to talk to us?",
                "address": "Address",
                "phoneAndWhatsapp": "Phone or WhatsApp",
                "phone": "Phone",
                "whatsapp": "Whatsapp",
            },
            "attractions":{
                "showingMoreResults": "Showing More Results",
                "noMoreResults": "No More Results",
                "NoResultsMatchedTheFilterValues": "No Results Matched the Filter Values",
                "inclusions": "Inclusions",
                "exclusions": "Exclusions",
                "overviewOfTheTrip":"Overview of the trip",
                "immediateConfirmation":"Immediate confirmation",
                "pricePerPerson":"Price per person",
                "totalForXXXPeople":"Total for XXX people",
                "toOrder":"To order",
                "freeCancelation":"Free cancelation",
                "showAll":"Show details",
                "hideAll":"Hide details",
                "days": "Days",
                "rating": "Rating",
                "supplier": "Supplier",
                "location": "Location",
                "pricePerPersonFrom": "Price per person from",
                "selectDatesAndTravelers": "Select dates and travelers",
                "bookNowWithFreeCancellation": "Book now with free cancellation",
                "updateSearch": "Update search",
                'activities-health-wellness-spas': 'Spas',
                'activities-action-entertainment-shows-events': 'Shows & events',
                'activities-nature-outdoors-zoos-aquariums': 'Zoos & aquariums',
                'activities-food-drink': 'Food & drink',
                'activities-action-entertainment-shopping': 'Shopping',
                'activities-nature-outdoors-tickets-passes': 'Tickets & passes',
                'activities-action-entertainment-rentals-rides': 'Rentals & rides',
                'activities-transportation': 'Transportation',
                'activities-culture-shows-events': 'Shows & events',
                'activities-nature-outdoors': 'Nature & outdoors',
                'activities-tours-by-theme-shopping-tours': 'Shopping tours',
                'activities-workshops-classes-sport-lessons': 'Sport lessons',
                'activities-action-entertainment-tickets-passes': 'Tickets & passes',
                'activities-workshops-classes-music-dance': 'Music & dance',
                'activities-food-drink-workshops-classes': 'Workshops & classes',
                'activities-health-wellness-relaxation': 'Relaxation',
                'activities-tours-by-theme-food-drink-tours': 'Food & drink tours',
                'activities-workshops-classes': 'Workshops & classes',
                'activities-health-wellness-beauty-treatments': 'Beauty treatments',
                'activities-action-entertainment-water': 'Water',
                'activities-workshops-classes-arts-crafts': 'Art & crafts',
                'activities-tours-by-theme': 'Tours by theme',
                'activities-action-entertainment-land': 'Land',
                'activities-tours-by-theme-sport-tours': 'Sport tours',
                'activities-culture': 'Culture',
                'activities-tours-by-theme-walking-tours': 'Walking tours',
                'activities-health-wellness-alternative-medicine': 'Alternative medicine',
                'activities-tours-by-theme-art-culture-tours': 'Art & culture tours',
                'activities-workshops-classes-cultural-immersion': 'Cultural immersion',
                'activities-health-wellness': 'Health & wellness',
                'activities-nature-outdoors-air-activities': 'Air activities',
                'activities-tours-by-theme-luxury-tours': 'Luxury tours',
                'activities-tours-by-theme-historical-heritage-tours':
                  'Historical heritage tours',
                'activities-culture-art-culture-tours': 'Art & culture tours',
                'activities-action-entertainment-air': 'Air',
                'activities-tours-by-theme-nature-wildlife-tours':
                  'Nature & wildlife tours',
                'activities-tours-by-theme-discovery-tours': 'Discovery tours',
                'activities-nature-outdoors-wildlife-nature-observation':
                  'Wildlife & nature observation',
                'activities-action-entertainment': 'Action & entertainment',
                'activities-tours-by-theme-museum-exhibition-tours':
                  'Museum & exhibition tours',
                'activities-workshops-classes-food-drink': 'Food & drink',
                'activities-tours-by-theme-virtual-tours': 'Virtual tours',
                'activities-tours-by-theme-special-tours': 'Special tours',
                'activities-nature-outdoors-land-activities': 'Land activities',
                'activities-transportation-rentals-rides': 'Rentals & rides',
                'activities-nature-outdoors-water-activities': 'Water activities',
                'activities-food-drink-restaurants-bars': 'Restaurants & bars',
                'activities-food-drink-food-drink-tours': 'Food & drink tours',
                'activities-no-category': 'No Category',
                'activities-health-wellness-retreats': 'Retreats',
                'activities-action-entertainment-sport-lessons': 'Sport lessons',
                'activities-tours-by-theme-self-guided-tours': 'Self-guided tours',
                'activities-action-entertainment-multi-adventure-games':
                  'Multi-adventure & games',
                'activities-food-drink-tickets-passes': 'Tickets & passes',
                'activities-tours-by-theme-night-tours': 'Night tours',
                'activities-culture-tickets-passes': 'Tickets & passes',
                'activities-transportation-transfers': 'Transfers',
                "hours": "Hours",

                "ages_between_min_max_range": `Ages Between {min_max_range}`,
                "ages_between": `Ages Between`,
                "sum_price_amount": `Sum Price: {amount}`,
                "sum_price": `Sum Price:`,
            },
            "packages": {
                "availableSeats":"Available seats",
                "from": "From date",
                "until": "Until date",
                "breakfastIncluded": "Breakfast Included",
                "includesMassageAndSpa": "Includes massage and spa!",
                "lastRoom": "Last room!",
                "includesTransfers": "Includes transfers",
                "includesActivities": "Includes attractions",
                "nights": "Nights",
                "withoutBaggage": "Without luggage",
                "directFlight": "Direct flight",
                "oneStop":"One Stop",
                "numOfStops":"Stops",
                "flightDetails": "Flight details",
                "flightFrom": " from -> to",
                "flightBack": " from -> to",
                "hours": "Hours",
                "flightNumber": "Flight number",
                "airLine": "Airline",
                "suitcaseWeight": "weightkg Baggage Included",
                "trollyWeight": "weightkg Trolley included",
                "includedActivitiesTitle": "Attractions and tours in the package",
                "checkAllPictures": "View all the pictures",
                "affiliateWith": "In collaboration with",
                "addButtonDisabledTooltip": "Order is limited to room quantity - cannot add another room. To change a room, remove a room from the cart",
                "holidaySchedule": "Vacation schedule",
                "passengers":"Passengers",
                "isRoundTrip": "Baggage Price for Round Trip *",
                "includesFlights": "Includes Flights",
                "returnFlight": "Return Flight",
                "outboundFlight": "Outbound Flight",
                "trollyWeight2": "weight kg trolley / bag",
                "suitcaseWeight2": "weight kg suitcase",
                "included": "Included",
                "notIncluded": "Not Included",
                "perPax": "Per Passenger",
                "addSuitcaseLater": "You can add later",
                "clickForDetails": "Click Here for Attraction Details",
                "includedInPackage": "Included in the Package!",
            },
            "roomPricing": {
                "for": "for",
                "addPassenger": "Add Passenger",
                "pricePerPerson": "Price per person",
                "pricePerRoom": "Price per room",
                "priceForAllRooms": "Price for all rooms",
                "payOnSpot": "Pay On Spot",
                "resort_fee": "Resort Fee",
                "occupancy_tax": "Occupancy Tax",
                "totalPrice": "Total Price",
                "pricePerInfant": "Price per infant",
                "composition": "Composition",
                "adultsInTheRoom": "Adults",
                "children": "Children",
                "adults": "Adults",
                "single": "Single adult",
                "child": "Child",
                "selectInfantCount": "Select from the list",
                "infant": "Infant",
                "1infant": "One infant",
                "2infants": "2 infants",
                "infants": "Infants",
                "noInfants": "Without infants",
                "all_include": "All inclusive",
                "breakfast": "Breakfast",
                "activities": "Choose a tour",
                "half_board": "Half board",
                "half_board_kosher": "Kosher half board",
                "full_board": "Full board",
                "sleep_only": "Room Only",
                "breakfast_kidush": "Friday morning and evening meal",
                "addToCard": "Add to order",
                "inPackageDates": "In package dates",
                "chooseRoom": "Choose room composition",
                "nights": "Nights",
                "boardType": "Board basis",
                "filters": "Filters",
                "noAvailableRooms": "No rooms available for the selected dates",
                "noAvailableRoomsForDates": "Room not available for the selected dates",
                "addInfant": "Add infant",
                "roomFacilities": "Room Facilities",
                "show_images": "Show Images",
                "autoApprove": "Auto Approve",
                "includedInPrice": "Included in Price",

                "until": "Until",
                "no": "No",
                "vat": "Vat",
                "is": "Is",
                "not": "Not",
                "city_tax": "City Tax",
                "cancellation_policy": "Cancellation Policy",
                "your_local_time": "*Your local Time",
                "additional_price_info": "Additional Price Info",
                "policy": {
                    "freeCancallationUntil": "Free cancellation at this rate until",
                    "freeCancallationUntil2": "Free Cancellation Until",
                    "cancallationBeCharged": "You will be charged",
                    "ifYouCancelBetween": "If you cancel from",
                    "ifYouCancelModifiedBetween": "If you cancelled or modified after",
                    "cancelFullPrice": "full price of the reservation will be charged",
                    "till": "till",
                    "rateNonRefundable": "The rate is non-refundable upon cancellation",
                },
            },
            "boards" : {
                "RO": "ROOM ONLY",
                "BB": "BED AND BREAKFAST",
                "HB": "HALF BOARD",
                "FB": "FULL BOARD",
                "AI": "ALL INCLUSIVE",
                "UI": "ULTRA DELUXE ALL INC",
                "BF": "BUFFE BRAKFAST",
                "KB": "COLD BUFFET BREAKFAST",
                "CB": "CONTINENTAL BREAKFAST",
                "AB": "AMERICAN BREAKFAST",
                "DR": "DINNER AND ROOM",
                "EB": "ENGLISH BREAKFAST",
                "FT": "FB+TREATMENTS",
                "F+": "FULL BORD PLUS",
                "GA": "GALA DINNER",
                "H6": "HB+ONLY 6 TREATMENTS",
                "HT": "HB+TREATMENTS",
                "IB": "ISRAELI BREAKFAST",
                "KO": "KOSHER",
                "KS": "OSHER BREAKFAST",
                "MI": "MAX ALL INCLUSIVE",
                "RB": "ROOM & BREAKFAST",
                "NN": "SEE ITINERARY",
                "SI": "SOFT ALL INCLUSIVE",
                "UL": "ULTRA ALL INCLUSIVE",
                "half_board": "Half board",
                "half_board_kosher": "Kosher half board",
                "full_board": "Full board",
                "sleep_only": "Room Only",
                "breakfast_kidush": "Friday morning and evening meal",

                "breakfast": "BB",
                "breakfast-for-2": "BB for 2",
                "nomeal": "Room Only",
            },
            "activitiesTranslations": {
                "addToOrder": "Add to order",
                "passenger": "To the passenger",
                "showMore": "Show more",
                "addAttractionsAndTrips": "Add attractions and trips",
                "doYouWantToAddAttractions": "Do you want to add attractions to your vacation?",
                "itsTime": "It's time!",
                "pricePerPerson": "Price per person",
            },
            "modal": {
                "viewAllImages": "View all images",
                "galleryTitle": "Photo gallery",
                "cancelationTerms": "Cancellation terms - ",
                "nameOfTheHotel": "Hotel details",
                "hotelInfo": "About the hotel",
                "hotelWebsite": "Link to the hotel website",
                "showMore": "Show More",
                "showLess": "Show Less",
            },
            "manageBaggage":{
                'manageBaggage':"Manage baggage",
                "and":"And",
                "save":"Save",
                "purchaseIsNotSupported":"purchase is not supported",
                "baggageIsForBothFlights":"You can add baggage for a round trip flight",
                "suitcase": "Suitcase",
                "trolly": "Trolley",
                "guest":"Guest"
            },
            "shoppingCartTranslations": {
                'attractionPriceDescription': "Attraction price depends on date, option selection and amount of ticket",
                'selectedDate': "Selected Date",
                'selectADate': "Select a Date",
                'availableDatesBetween': "Available Dates Between",
                'Options': "Options",
                'Text': "Text",
                'Ticket type': "Ticket type",
                'Start Time': "Start Time",
                'Pick Up Location': "Pick Up Location",
                'Custom Pick Up Location': "Custom Pick Up Location",
                'Drop Off Location': "Drop Off Location",
                'Custom Drop Off Location': "Custom Drop Off Location",

                'manageBaggage':"Manage baggage",
                "youCanAddGuestsOnlyUpToTheAvailableSeats":"You can add guests only up to the available seats",
                "shoppingCartButtonTooltip": "Choose a composition and add to order",
                "totalPrice": "Total payment",
                "proceedToPayment": "Proceed to payment",
                "placeOrder": "Proceed to registration",
                "NoHotelWasChosenForTheVacation": "No hotel was chosen for the vacation",
                "betweenDates": "Between the dates",
                "packageInformation": "Package details",
                "rooms": "Room details",
                "transfers": "Transfers",
                "reg_transfers": "Transfers/shuttles to and from the hotel",
                "shuttle_transfers": "Shuttles from the border to the hotel and back",
                "flights": "Flights",
                "activities": "Attractions",
                "details": "Details",
                "shoppingCart": "Shopping Cart",
                "package": "Nights at the hotel",
                "nights": "Nights",
                "inHotel": "In the hotel",
                "apply": "Confirm",
                "promoCode": "Coupon code",
                "yourShoppingCart": "Your Shopping Cart",
                "isEmpty": "Still empty...",
                "myVacation": "My vacation",
                "showAll": "Show all »",
                "beforeDiscount": "Total before discount -",
                "agentCommission": "Agent commission -",
                "agentCommissionNet": "Agent commission (net payment) -",
                "discountFromCode": "Code discount -",
                "affiliateWith": "In collaboration with -",
                "tpId": "Order ID -",
                "chargeTypeFullCC": "Full payment - including commission",
                "chargeTypeNetCC": "SubscriptionPayment for net price",
                "chargeTypeCash": "Cash transaction",
                "agentCommissionPrec": "Agent commission percentage",
                "priceOff": "Discount",
                "percentageOff": "Discount percentage",
                "selectedItems": "Selected items",
                "viewProduct": "View a Product",
                "infantAdditionalPrice": "Infant additional price",
                "netPriceWarning": "Net Price, Please add profit!",
                "copyOrderLink": "Copy Link",
                "createOrder": "Save Offer",
                "updateCommission": "Save",
                "commissionNotSaved": "Please click to save!",
                "clearCart": "Click to clear cart",
                "assignCustomer": "Assign Customer",
                "firstName": "First Name",
                "lastName": "Last Name",
                "phone": "Phone",
                "comment": "Comment",
                "email": "Email",
                "wrongEmailFormat": "Incorrect email format",
                "saveCustomer": "Save customer",
                "updateCustomerTitle": "Update customer",
                "createCustomerTitle": "Create new customer",
                "sendByWhatsApp": "Send by WhatsApp",
                "copyText": "Copy Text",
                "sendBySMS": "Send by SMS",
                "findQuoteFor": "Send Offer via",
                "linkIsGive": "Quote is Live",
                "dates": "Dates",
                "hotelName": "Hotel name",
                "flightPrice": "Flight price",
                "suitcase": "Suitcase",
                "trolly": "Trolley",
                "passengers": "Passengers",
                "totalAgentComm": "Total agent commission",
                "agentOptions": "Agent Options",
                "compareDisableForAgent": "Price comparison is available when there are 2 quotes in the cart",
                "createNewUser": "Create New Offer",
                "comparePrice": "Compare Prices",
                "send": "Send Offer",
                "profitPercentageChange": "Change Profit",
                "or": "Or",
                "approval": "Approval",
                "profitPerPerson": "Profit per person",
                "grossProfitPercentage": "Percentage Profit",
                "actionNotAllowed": "Action not allowed",
                "orderIsAlreadySaved": "Order is already saved and no additional products can be added",
                "clickToClear": "Click to clear the cart",
                "roomsByRequestSubjectOfHotelAvailability": "Unable to add the same type of room, please select a different type of room",
                'roomsByRequestSubjectOfHotelAvailabilityTitle':"Failed to add room",

                "attractionAlreadyAdded": "Unable to add the same type of attraction, please select a different type of attraction",
                'failedToAddAttraction':"Failed to add attraction",
                "noAvailabilityOnThisDates": "No availability for selected dates",
                "attractionSearchFailed": "Attraction Search Failed",
                "cannotSearchForThePastDates": "Cannot Search For The Past Dates",
                'searchOtherForDates':"There is no availability, please search for other available dates",
                "adult": "Adult",
                "child": "Child",
                "infant": "Infant",
                "perPerson": "Per Person",
                "perVehicle": "Per Vehicle",
                "group": "Group",
                "family": "Family",
                "perEquipment": "Per Equipment",
                "perBoat": "Per Boat",
                "none": "None",
            },
            "checkout": {
                "addBaggage":"Add Baggage",
                "newGuest":"New guest",
                "chooseExistingGuest":"Use existing guest",
                "additionalBaggage": "Additional Baggage",
                "free": "free",
                "baggageIsForBothFlights":"You can add baggage for a round trip flight",
                "additionalBaggageForThisGuest": 'Additional Baggage For This Guest',
                "orderFailedToLoad":"An error occurred while loading the offer",
                "cartNoLongerValid":"The cart is no longer valid",
                "room": "Room",
                "personalInfo": "Order Details",
                "paymentDetails": "Payment",
                "orderCompletion": "Finish Order",
                "userDetails": "Client info",
                "name": "First Name",
                "lastName": "Family Name",
                "passport": "Passport",
                "inEnlgish": "",
                "birthdate": "Birthdate",
                "phone": "Phone",
                "email": "Email",
                "notesForTheOrder": "Notes for the order",
                "namesOfGuests": "Passengers",
                "namesMustMatchPassport": "* All names must be typed in English exactly as they appear on the passport",
                "goToPayments": "Submit and proceed to payment",
                "saveOrder": "Save order",
                "agreement": "I confirm the website's terms, order terms, and the order cancellation terms",
                "men": "Male",
                "women": "Female",
                "pleaseInputYourPhone": "Please enter your phone number",
                "pleaseInputYourLastName": "Please enter your last name",
                "pleaseInputYourFirstName": "Please enter your first name",
                "pleaseInputYourEmail": "Please enter your email",
                "pleaseInputYourPassportNumber": "Please enter your passport number",
                "pleaseInputYourDateOfBirth": "Please enter your date of birth",
                "pleaseSelectGender": "Please select gender",
                "maxChildAge": "Child between the ages 2-",
                "maxInfantAge": "Infants must be under two years old at the time of the vacation",
                "pleaseAgreeWithT&C": "Please agree to the terms of use and website regulations",
                "viewTerms": "View regulations",
                "invalidEmail": "Invalid email",
                "orderCreateSuccess": "Your order has been saved successfully, you are being redirected to payment",
                "orderCreateFree": "Your order has been saved successfully!",
                "orderUpdateSuccess": "Your order has been updated successfully!",
                "mainRoomGuestTitle": "Room named after",
                "uploadPassportImage": "Upload passport photo",
                "addInfant": "Add infant",
                "updateInfant": "Update infant quantity",
                "howManyInfantsDoYouWantToAdd": "Choose the number of infants for this room",
                "add": "Add",
                "setInfantCountForRoom": "Update infant quantity",
                "setInfantCountForRoomSuccess": "The number of infants has been updated successfully",
                "infantsAddedToTheRoom": "Infants added to the room",
                "selectNumberOfInfants": "Choose number of infants",
                "pricePerInfant": "Price per infant",
                "infantsRemoved": "Infants were removed from this room",
                "requiredUploadPassportImage": "Uploading passport photo is mandatory",
                "infantError4403": "Cannot change the number of infants - please contact the service center",
                "docketId": "* Docket ID",
                "agencyId": "* Agency ID"
            },
            "manageOrder": {
                "questions": "Questions",
                "moveToPayment": "Add Payment",
                "backToManage": "Return to Order",
                "paymentTitle": "Order Payment",
                "manageTitle": "Update Order Info",
                "modelNotFound": "Order not found",
                "orderId": "Order management"
            },
            "attractionQuestions": {
                "customerInfo": "Customer Info",
                "participantsInfo": "Participants Info",

                "first_name": "First Name",
                "last_name": "Last Name",
                "email_address": "Email Address",
                "phone_number": "Phone Number",
                "pickup_location": "Pickup Location",
                "height_in_centimeters": "Height in Centimeters",
                "weight_in_kilograms": "Weight in Kilograms",
            },
            "completionForm": {
                "paymentFinish": {
                    "title": "The order was made successfully - payment completed",
                    "info": "An email with order details was sent to your address"
                },
                "waitingToApprove": {
                    "title": "Your order has been sent for approval",
                    "info1": "Order details were saved in the system and sent for approval.",
                    "info2": "SubscriptionPayment will only be made after order approval.",
                    "info3": "An email with order confirmation and payment will be sent after order approval."
                },
                "paymentFail": {
                    "title": "SubscriptionPayment failed",
                    "info1": "SubscriptionPayment failed :(",
                    "infoFail": "After several attempts, the charge failed - please try again",
                    "infoWhatsapp": "If an error occurred and you cannot pay, please contact via WhatsApp at: "
                },
                "orderNum": "Order number",
                "thanksForChoose": "Thank you for choosing",
                "free": {
                    "title": "Registration was successful",
                    "info": "An email with a summary of the order was sent to you"
                },
                "pay_later": {
                    "title": "Registration was successful",
                    "info": "An email with order confirmation was sent to you"
                }
            },
            "paymentForm": {
                "priceCanNotBeOverBalance":"Price can not be over balance",
                "total":"Total link",
                "balance":"Total Balance",
                "updatedBalance":"updatedBalance",
                "copyLink":"Copy link",
                "linkFor":"Link for",
                "back":"Back",
                "setSpecificPrice":"Payment Link",
                "createLink":"Create link",
                "setPriceAndSendPaymentLinkToClient":"Set price and send payment to client",
                "clientName": "Client Name",
                "paymentPageFor": "Payment Page For",
                "forPayment": "For Payment",
                "price": "Price",
                "copyPaymentLink": "Copy Payment Link",
                "pleaseFillInBothFields": "Please fill in both fields.",
                "paymentLinkCopiedToClipboard": "Payment link copied to clipboard!",
                "createLinkForPrice": "Create Link for Payment",
                "creditNotSaveInBd": "Credit card details are not saved on the site - you are making a secure payment",
                "title": "Enter payment method (Visa & Mastercard ONLY)",
                "splitBtn": "Click to split credit cards",
                "splitTitle": "Split credit cards",
                "splitDesc": "Choose the number of credit cards for splitting and click confirm",
                "noSplit": "No split",
                "noPayments": "No payments",
                "payments": "Payments",
                "paymentsNum": "Number of payments",
                "withDebit": "With credit",
                "splitBy": "Credit cards",
                "toPay": "To pay",
                "splitApprove": "Confirm",
                "currencyRate": "Charging is done in shekels - according to the tourism rate",
                "splitCurrentCardNum": "Credit split",
                "splitCurrentCardNumFrom": "Out of",
                "errorMessageToRetry": "Attempt No. {retryNum} - SubscriptionPayment failed from the credit card company. Please enter again card details or another card. Website accepts VISA and MASTERCARD cards only.",
            },
            "hotelTypes": {
                "Hotel": "Hotel",
            },
            "hotelDetails": {
                "updateDates": "Update dates",
                "roomTypesToChooseFrom": "Room types to choose from",

                "metapolicy": "Hotel Policy",
                "metaExtraInfo": "Additional Information",
                "not_included": "Not Included",
                "included": "Included",
                "price": "At the cost of",
                "address": "Address",
                "other": "Address",
                "Location": "Hotel Location",
                "At the boutique hotel": "About the Hotel",
                "Room amenities": "Room Amenities",
                "parking": "Parking",
                "pets": "Pets",
                "shuttle": "Shuttle to Airport (One Way)",
                "internet": "Internet Services",
                "extra_bed": "Extra Bed",
                "add_fee": "Additional Fee",
                "children_meal": "Children's Meal",
                "check_in_check_out": "Check-In and Check-Out",

            },
            "sideFilters": {
                "sortBy": "Sort By",
                "recommended": "Recommended",
                "price": "Price",
                "clearSideFilters": "Clear Filters",
                "ts_type": "Flight Types",
                "auto_approve": "Instant Approval",
                "include_transfer": "Include Transfer",
                "include_baggage": "Include Baggage",
                "include_trolly": "Include Trolly",
                "auto_approve_checked": "Showing Instant Approval Results",
                "from_date_hours": "Outbound Flight Time",
                "return_date_hours": "Return Flight Time",
                "airline": "Airline",
                "2": "Charter Flights",
                "3": "Scheduled Flights",
                "nonStop": "Direct Flight",
                "stop": "One Stop",
                "stops_plural": "Stops",
                "hotel_type": "Accommodation Types",
                "stops": "Number of Stops",
                "freeCancellation": "Free Cancellation?",
                "freeCancellationLabels": {
                    "1": "Only Free Cancellation",
                    "0": "Without Free Cancellation"
                },

                "feature": "Feature",
            },
            "package_feature": {
                "SPECIAL PACKAGE": "Special Package",
                "SKI": "Ski",
                "EVENT": "Event",
                "SPORT": "Sport",
                "SPA": "Spa",
                "YOUNG VACATION": "Young Vacation",
                "FLT+HTL+CAR": "FLT+HTL+CAR",
            },
            "hotelAmenities": {
                // "dummy": "dummy"
            },
            "general": {
                "attraction":"Attraction",
                "includeReturnSuitcase":"Suitcase included for flight back",
                "total": "Total",
                "as_of_today": "as of today",
                "link_to_the_offer": "Link to the offer",
                "the_quote_has_been_copied_successfully": "The quote has been copied successfully",
                "base": "Basis",
                "sort": "Sort",
                "submit": "Submit",
                "maxAmountOf": "Max Amount Of",
                "minAmountOf": "Min Amount Of",
                "ticketType": "Ticket Type",
                "cancellation_policy": "Cancellation Policy",
                "additionalInfo": "Additional Information",
                "hour": "Hour",
                "minute": "Minute",
                "before": "Before",
                "date": "Date",
                "duration": "Duration",
                "quantity": "Quantity",
                "for": "for",
                "free": "free",
                "freeOfCharge": "Free of Charge",
                'option': 'Option',
                'removeSelection': 'Unselect',
                'grandTotalPrice': 'Grand Total Price',
                'startingPrice': 'Starting price',
                "usd": "USD",
                "euro": "EURO",
                "address": "Address",
                "type": "Type",
                "noResults": "No results",
                "boardBasis": "Board Basis",
                "nights": "Nights",
                "supplier": "Supplier",
                "airLine": "AirLine",
                "price": "Price",
                "totalRoomPrice": "Total Price",
                "priceForXRooms": "Price for X rooms",
                "hotel": "Hotel",
                "hotel_stars": "Hotel Stars",
                "minPrice": "Min Price",
                "BB": "BB",
                "HB": "HB",
                "cancel": "Cancel",
                "replaceShoppingCart": "Replace Shopping Cart",
                "replace": "Replace",
                "searchForUser": "Search For User",
                "acceptCookiesTitle":"This website uses cookies to ensure you get the best experience on our website.",
                "acceptCookiesBtn":"Accept",
                "includesActivities": "{NUMBER} gift attractions!",
                "includesActivity": "Includes a gift attraction!",
                "adults": "Adults",
                "all_include": "All inclusive",
                "breakfast": "Breakfast",
                "half_board": "Half board",
                "half_board_kosher": "Kosher half board",
                "full_board": "Full board",
                "sleep_only": "Accommodation only",
                "breakfast_kidush": "Friday breakfast and dinner",
                "addToCard": "Add to order",
                "chooseRoom": "Choose composition and room",
                "boardType": "Accommodation basis",
                "filters": "Filters",
                "rating": "Hotel rating",
                "attraction_stars": "Attraction rating",
                "selected": "Selected",
                "name": "Name",
                "taxonomies": "Taxonomies",
                "children": "Children",
                "toDate": "Until",
                "transfers": "Transfers",
                "from": "Starting from",
                "perPerson": "Per person",
                "perPax": "Per Pax",
                "include": "Includes",
                "noIncludeText": "Vacation packages",
                "flights": "Flights",
                "vacationAt": "Vacation in {DEST}",
                "fieldRequired": "Mandatory field",
                "showAll":"Show All",
                "showLess":"Show Less",
                "flight" : "flight",
                "h": "h",
                "min": "min",

                "availableSeats": "Available Seats",
                "showGrossPrice": "Show Gross Price",
                "showNetPrice": "Show Net Price",
                "noRating": "No Rating",
                "bundleFlight": "Round Trip Flight",
                "trollyIsIncluded": "Trolley Included",
                "trollyIsNotIncluded": "No Trolley",
                "suitcaseIsIncluded": "Suitcase Included",
                "suitcaseIsNotIncluded": "Suitcase Not Included",
                "additionalSuitcases": "Includes X Suitcases",
                "additionalTrolly": "Includes X Trolleys",
                "returnFlight": "Return Flight",
                "outboundFlight": "Outbound Flight",
                "agentMode": "Agent Mode",
                "userMode": "Client Mode",
                "suitcase": "Suitcase",
                "trolly": "Trolley",
                "packageSearchResult": "The packages we found for you",
                "day": "Day",
                "selectDate": "Select Dates",
                "search": "Search",
                "adult": "Adult",
                "child": "Child",
                "infant": "Infant",
                "infants": "Infants",
            },
            "subsidizedForm": {
                "title": "Add subsidy",
                "employee_id": "Employee number",
                "person_id": "ID number (9 digits)",
                "validate": "Check employee code",
                "alreadyApplied": "Subsidy already applied",
                "addOneMore": "Add another subsidy",
                "addOneMoreId": "Add another employee",
                "fieldRequired": "Mandatory field",
                "priceReducedBy": "Price reduced by",
                "forEmployee": "For employee",
                "employeeIs": "Successfully identified",
                "error4401": "Failed to identify, try again",
                "error4402": "Code identified, but already in use",
                "guestIdentifiedId": "Identified by ID",
                "guestNotIdentified": "You must identify before registering",
                "clickToIdentify": "Click to identify"
            },
            "uploadImage": {
                "uploadPassportImage": "Upload passport photo",
                "somethingWentWrong": "Something went wrong"
            },
            "flightButton":{
                "cardConditions": "Card conditions",
                "flightTotalPrice": "One-Way Flight Price",
                "total": "Total",
                "continueToOrder": "Move to Order",
                "addToCart": "Add to Cart",
                "lastPlace": "Last Seats Available",
                "pricePerPassenger": "Price per Passenger",
            },
            "agentCommission":{
                "supplier": "Supplier",
                "commission": "Commission",
                "recommendedFee": "Recommended Fee",
                "transactionDetailsForAgent": "Agent Details",
                "supplierNotes": "Supplier Notes",
                "cancellationConditions": "Cancellation Conditions",
                "addToCartToChangeFree": "Add to Cart to Change Commission",
                "pricesShownAreNetPrices": "Net prices are shown",
                "pricesShownAreGrossPrices": "Gross prices are shown",
                "packageRemarks": "package remarks",
            },

        }
    }
};

export default translationsEn;