import apiClient from '@skygroup/shared/API/apiClient';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setIsUpgradePlanModalOpen, updateAgentInfo } from '../store/config';
import endpoints from '../utils/endpoints';
import {useNavigate} from "react-router-dom";
import { isEmpty } from 'lodash';

const agentInfoApi = (token: string) => {
  return apiClient
    .post(`${endpoints.AGENT.POST_INFO}`, { token: token })
    .then((res) => {
      return res.data;
    });
};

export const useLoadAgentUser = () => {
  const dispatch = useDispatch();
  const [isAgentLoading, setIsAgentLoading] = useState(false);
  const [agentInfo, setAgentInfo] = useState<any>({});
  const navigate = useNavigate();

  const getAgentInfo = useCallback(async () => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const token = searchParams.get('t') || localStorage.getItem('agent-admin-token');
      if (!token) {
        return;
      }

      //when we load token - we want to remove it from url ASAP
      if (searchParams.get('t')) {
        const newUrl = location.pathname;
        navigate(newUrl, { replace: true });
      }

      setIsAgentLoading(true);
      let userInfoRes: any = await agentInfoApi(token);

      if (userInfoRes.status === 'success') {
        const priceViewLocalStorage = JSON.parse(
          localStorage.getItem('price_view') || '{}'
        );
        const priceView = isEmpty(priceViewLocalStorage) ? userInfoRes.data.agent.price_view : priceViewLocalStorage;

        const payment_status = userInfoRes.data.agency?.subscription?.payment_status;

        // if (payment_status === null || (payment_status && payment_status === 'none')) {
        //  Question for Nir: MAYBE WE SHOULD ALSO CHECK FOR payment_status === null? like in example above?
        if (payment_status && payment_status === 'none') {
          // this means user not paid yet - force him to free
          userInfoRes.data.agency.subscription.plan_id = 'agent_360_light';
        } else if (payment_status && (payment_status === 'fail' || payment_status === 'fail_force')) {
          dispatch(
            setIsUpgradePlanModalOpen({ type: 'update-card', data: {disabledClose: payment_status === 'fail_force'} })
          );
        }

        const agentInformation = {
          ...userInfoRes.data,
          agentMode: true,
          agent:{
            ...userInfoRes.data.agent,
            price_view:priceView
          }
        };
        dispatch(updateAgentInfo(agentInformation));
        setAgentInfo(agentInformation);
        localStorage.setItem('agent-admin-token', token);
      } else {
        dispatch(updateAgentInfo({}));
        setAgentInfo({});
        localStorage.removeItem('agent-admin-token');
        localStorage.removeItem('price_view');
      }
      setIsAgentLoading(false);
    } catch (error: any) {
      dispatch(updateAgentInfo({}));
      setAgentInfo({});
      localStorage.removeItem('agent-admin-token');
      localStorage.removeItem('price_view');
      setIsAgentLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    getAgentInfo();
  }, [getAgentInfo]);

  return { isAgentLoading, agentInfo };
};
