import React, { useMemo } from 'react';
import { Product } from '../../ShoppingCartSliceV2';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { hotelLink } from '../../../../utils/LinkGenerator';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import ProductPreviewComponent from '../../widgets/ProductPreviewComponent';

interface Props {
  product: Product;
  ProductRenderer: (
    product: Product,
    identifier?: string,
    isTopLevel?: boolean,
    isSameDates?:boolean
  ) => JSX.Element | null;
  identifier?: string;
  isTopLevel?: boolean;
  showProductPrice?: boolean;
}

const isAreSameDates = (subProducts: any, dateFormat: string) => {
  if (subProducts.length === 0) return false;
  const formattedStart = moment(subProducts[0].date_start * 1000).utc().format(dateFormat);
  const formattedEnd = moment(subProducts[0].date_end * 1000).utc().format(dateFormat);
  for (let i = 1; i < subProducts.length; i++) {
    const currentStart = moment(subProducts[i].date_start * 1000).utc().format(dateFormat);
    const currentEnd = moment(subProducts[i].date_end * 1000).utc().format(dateFormat);
    if (formattedStart !== currentStart || formattedEnd !== currentEnd) {
      return false;
    }
  }
  return true;
};

const ShoppingCartHotel = ({
  product,
  ProductRenderer,
  identifier,
  isTopLevel,
  showProductPrice = true,
}: Props) => {
  const { siteConfig } = useSelector((data: RootState) => data.config.config);
  const { shoppingCartTranslations: t } = useSelector(
    (data: RootState) => data.config.translations
  );

  const isSameDates = isAreSameDates(product.subProducts, siteConfig.dateFormat);


  function generateLink(obj: any) {
    const startDate = moment(
      (obj?.date_start ||
        obj?.subProducts?.[0]?.product_data?.date_range?.start ||
        obj?.subProducts?.[0]?.date_start) * 1000
    ).utc();
    const endDate = moment(
      (obj?.date_end ||
        obj?.subProducts?.[0]?.product_data?.date_range?.end ||
        obj?.subProducts?.[0]?.date_end) * 1000
    ).utc();

    const formattedStartDate = startDate.format(siteConfig.dateFormat);
    const formattedEndDate = endDate.format(siteConfig.dateFormat);

    const link = `?adult=${
      obj.subProducts[0].product_data.adult
    }&from=${encodeURIComponent(formattedStartDate)}&to=${encodeURIComponent(
      formattedEndDate
    )}${
      obj.subProducts[0].product_data.child
        ? '&child=' + obj.subProducts[0].product_data.child
        : ''
    }`;

    return link;
  }

  return (
    <div className={'product-top hotel-product'}>
      <div className={'product-title'}>
        {/* {product.identifier && (
              <Link to={hotelLink(product.identifier, searchParams)}>
                {product.name}
              </Link>
          )} */}
        {isTopLevel && (
          <ProductPreviewComponent
            productPath={hotelLink(product.identifier as string, generateLink(product))}
            label={product.name}
          />
        )}

        {!isTopLevel && (
          <div className={`product-preview-wrapper without-cursor-pointer `}>
            <b>{product.name}</b>
          </div>
        )}
      </div>

      <div className={'sub-products-title'}>{t?.rooms}</div>

      {product?.subProducts?.[0]?.date_start &&
        product?.subProducts?.[0]?.date_end && isSameDates && (
          <div className="package-general-info">
            <b>{t.dates}</b>
            <div className="title">
              {`${moment((product.subProducts[0].date_start as any) * 1000)
                .utc()
                .format(siteConfig.dateFormat)}
              -
              ${moment((product.subProducts[0].date_end as any) * 1000)
                .utc()
                .format(siteConfig.dateFormat)}`}
            </div>
          </div>
        )}

      {product?.subProducts?.map((product: Product) =>
        ProductRenderer(product, identifier, undefined,isSameDates)
      )}
    </div>
  );
};

export default ShoppingCartHotel;
