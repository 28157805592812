import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { Alert, notification } from 'antd';

export enum NotificationType {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
}

interface Props {
  message?: string;
  type?: NotificationType;
}

const useShoppingCartNotification = () => {
  const { shoppingCartTranslations: t, crud: t_crud } = useSelector(
    (data: RootState) => data.config.translations
  );
  const createNotification = ({
    message = `${t.shoppingCart} ${t_crud.updatedSuccessfully}`,
    type = NotificationType.Success,
  }: Props) => {
    const key = `open${Date.now()}`;

    // // hide the notification by default and show when
    // // screen width is less than 750px, css inside:
    // // index.less => .shopping-cart-notification {display: block;...
    notification.open({
      message,
      type,
      key,
      className: 'shopping-cart-notification',
      duration: 5,
      placement: 'bottomRight',
    });
  };

  return createNotification;
};

export default useShoppingCartNotification;
