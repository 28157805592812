import React, { useState, } from 'react';
import { Button, } from 'antd';
import { Product, setWholeCart } from '../../ShoppingCartSliceV2';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { Link } from 'react-router-dom';
import { packageLink } from '../../../../utils/LinkGenerator';
import './shoppingCartPackage.less';
import { MainProductType } from '../../interfaces';
import { isEmpty } from 'lodash';
import FlightCard2 from '../../../SearchDatePicker/widgets/SearchResults/FlightCard2';
import ProductPreviewComponent from '../../widgets/ProductPreviewComponent';
import useHandleManageBaggage from '../../hooks/useHandleManageBaggage';
import BaggageManagement from '@skygroup/shared/components/baggageManagment/baggageManagment';
import useSiteLang from '../../../../hooks/useSiteLang';

interface Props {
  ProductRenderer: (
    product: Product,
    identifier?: string
  ) => JSX.Element | null;
  subProducts: Product[];
  product: Product;
  identifier?: string;
  showProductPrice?: boolean;
}

const ShoppingCartPackage = ({
  subProducts,
  product,
  ProductRenderer,
  identifier,
  showProductPrice = true,
}: Props) => {
  const { shoppingCartTranslations: t, manageBaggage } =
    useSelector((data: RootState) => data.config.translations);
  const { siteConfig } = useSelector((data: RootState) => data.config.config);
  const { ShoppingCartV2 } = useSelector(
    (state: RootState) => state.shoppingCartV2
  );
  const subProductFlights = subProducts.filter((subProduct) => {
    return subProduct.type === MainProductType.Flight;
  });
  const flightFromSubProducts = subProductFlights[0].subProducts;
  const flightBackSubProducts = subProductFlights[1].subProducts;

  const dispatch = useDispatch();

  const subProductWithoutFlights = subProducts.filter((subProduct) => {
    return subProduct.type !== MainProductType.Flight;
  });

  const flight = isEmpty(subProductFlights)
    ? false
    : {
        ...subProductFlights[0].product_data,
        ...subProductFlights[1].product_data,
      };

      const [isManageBaggageOpen, setIsManageBaggageOpen] = useState(false);

      const { config } = useSelector((data: RootState) => data.config);

      const { direction } = useSiteLang({
        siteConfig: config?.siteConfig,
      });

      const {
        handleAddBaggage,
        flightBackBaggage,
        flightFromBaggage,
        isRoundTrip,
        isShowManageBaggageButton,
      } = useHandleManageBaggage(product);

  return (
    <div className="shopping-cart-package">
      {/* <Link to={packageLink(product)} className="link">
        <b>{t.package}</b>
      </Link> */}
        <ProductPreviewComponent
          productPath={packageLink(product)}
          label={t.package}
          labelClassName={'link'}
        />
      
      <div className="package-general-info">
        <b>{t.dates}</b>
        <div className="title">
          {`${moment(product.product_data.dates.start * 1000).utc().format(
            siteConfig.dateFormat
          )}
          -
          ${moment(product.product_data.dates.end * 1000).utc().format(
            siteConfig.dateFormat
          )}`}
        </div>

        <b>{t.hotelName}</b>
        <div className="title">{product.product_data.hotel.name}</div>
      </div>

      {subProductWithoutFlights.map((subProduct: Product) =>
        ProductRenderer(subProduct, identifier)
      )}
    <b className="product-title">
            {t?.flights}{' '}
            {isShowManageBaggageButton && (
              <Button
                onClick={() => setIsManageBaggageOpen(true)}
                className="btn-style-4"
              >
                {t.manageBaggage}
              </Button>
            )}
      </b>

        {isManageBaggageOpen && (
          <BaggageManagement
            guests={ShoppingCartV2.guests}
            onChange={handleAddBaggage}
            flightBackBaggage={flightBackBaggage}
            flightFromBaggage={flightFromBaggage}
            mainProduct={product}
            shoppingCart={ShoppingCartV2}
            onClose={(cart: any) => {
              dispatch(setWholeCart(cart));
            }}
            isManageBaggageOpen={isManageBaggageOpen}
            setIsManageBaggageOpen={setIsManageBaggageOpen}
            onSave={() => setIsManageBaggageOpen(false)}
            isRoundTrip={isRoundTrip}
            t={manageBaggage}
            direction={direction}
          />
        )}

      <FlightCard2
        flightFromSubProducts={flightFromSubProducts}
        flightBackSubProducts={flightBackSubProducts}
        flightData={flight}
        isShowFlightButton={false}
        handlePurchase={() => {}}
        isMinimized
        showProductPrice={showProductPrice}
      />
    </div>
  );
};

export default ShoppingCartPackage;
