import React from 'react';
import { Product } from '../../ShoppingCartSliceV2';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import './shoppingCartAdditionalItem.less';

interface Props {
  subProducts?: Product[];
  text: string;
  isBaggages?: boolean;
  showProductPrice?: boolean;
}
const ShoppingCartAdditionalItem = ({
  subProducts,
  text,
  isBaggages = false,
  showProductPrice = true,
}: Props) => {
  const { ShoppingCartV2 } = useSelector(
    (data: RootState) => data.shoppingCartV2
  );

  const { general: general_t } = useSelector(
    (data: RootState) => data.config.translations
  );
  if (subProducts?.length === 0 || !subProducts) return null;

  return (
    <div className="shopping-cart-additional-items">
      {subProducts?.map((subProduct, i) => {
        return (
          <div key={i} className="additional-item">
            <div>
              <span>X {subProduct.product_amount} </span>
              <span className="additional-item-amount">
                {text} {isBaggages ? `- ${general_t[subProduct.type]}` : ''}
              </span>
            </div>
            {showProductPrice && <div>
              {ShoppingCartV2.currency_sign}

              {(
                subProduct?.total_price /
                (subProduct?.product_data?.is_round_trip ? 2 : 1)
              )?.toFixed(0)}
            </div>}
          </div>
        );
      })}
    </div>
  );
};

export default ShoppingCartAdditionalItem;
