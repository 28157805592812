import React from 'react';
import './updateCardWarning.less';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

const UpdateCardWarning = ({ updateCard_t }: { updateCard_t: any }) => {
  const handleOnClick = () => {
    const src = `${process.env.REACT_APP_BO_BASE_URL}/account-subscription?cardAutoOpen=1`;
    window.open(src, '_blank');
  };

  return (
    <div className="update-card-warning-wrapper">
      <h2>{updateCard_t.payment_failed_please_update_card}</h2>
      <Button
        className="btn-style-5 primary upgrade-btn"
        onClick={handleOnClick}
      >
        {updateCard_t.open_the_payment_form}
      </Button>
    </div>
  );
};

export default UpdateCardWarning;
