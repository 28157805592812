import { useSelector } from 'react-redux';
import { Product } from '../../ShoppingCartSliceV2';
import React, { useMemo } from 'react';
import { RootState } from '../../../../store/store';
import FlightCard2 from '../../../SearchDatePicker/widgets/SearchResults/FlightCard2';
import ProductPreviewComponent from '../../widgets/ProductPreviewComponent';

interface Props {
  product: Product;
  showProductPrice?: boolean;
}

const ShoppingCartFlight: React.FC<Props> = ({ product, showProductPrice = true }) => {
  const { shoppingCartTranslations: t } = useSelector(
    (data: RootState) => data.config.translations
  );

  // FINISH THIS LOGIC IN feat/SKY-720-B2C_preview-mode-for-product

  // const productPath = useMemo(() => {
  //   if (!product.identifier) {
  //     return null
  //   }
  //   const baseUrl = process.env.REACT_APP_BASE_URL;
  //   const productPath = ''; //packageLink(product);
  //   const searchParams = new URLSearchParams(packageLink(product));
  //   searchParams.append("prvmd", "1");
  //   const url = new URL(baseUrl + productPath);
  //   url.search = searchParams.toString();
  //   return url.toString();
  // }, [product]);

  return (
    <div>
      <div className="shopping-cart-flights">
        <div className="product-title">{t?.flights}</div>
        {/* <ProductPreviewComponent
                  productPath={flightLink(
            FlightType.BundleFlight,
            product.identifier,
            generateFlightLink(product.product_data)
          )}
          label={
            <>
              {flightFrom.from.name} →{' '}
              {flightFrom.to.name}
              {flightBack
                ? ` → ${flightFrom.from.name}`
                : ''}
            </>
          }
            labelClassName={'flight-link'}
        /> */}
        <FlightCard2
          flightData={product.product_data}
          isShowFlightButton={false}
          handlePurchase={() => {}}
          isMinimized
          showProductPrice={showProductPrice}
        />
      </div>
    </div>
  );
};

export default ShoppingCartFlight;
