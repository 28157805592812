import moment from "moment/moment";

export function getTechStopsText({
  amount,
  searchDatePicker_t,
}: {
  amount: any;
  searchDatePicker_t: any
}) {
  return `${
    amount && amount > 0 ? ` + ${amount} ${searchDatePicker_t.tech_stops},` : ''
  }`;
}

export function getFromToText(currentFlight: any, general_t: any, moment: any, siteConfig:any, flightNum:number) {
  const flightDuration = moment.duration(currentFlight?.duration);
  const flightDurationHours = Math.floor(flightDuration.asHours());
  const flightDurationMinutes = flightDuration.minutes();
  const flightDurationDays = Math.floor(flightDuration.asDays());

  const flightIataCodes = `${general_t.flight} ${flightNum}:  ${currentFlight.from.name} → ${currentFlight.to.name}`;
  const flightNumAndAirline = `${currentFlight.airline.name}  ${currentFlight.airline.id}-${currentFlight.flight_number}
${moment(currentFlight.departure_date * 1000).utc().format(siteConfig.dateFormat + ' HH:mm')}`;

  return `${flightIataCodes}\n ${flightNumAndAirline}`;

}

export function getFlightsArrStopAsText({
  flights,
  general_t,
  searchDatePicker_t,
  moment,
  acc = '',
  index = 0,
  siteConfig
}: {
  flights: any[];
  general_t: any;
  searchDatePicker_t: any;
  moment: any;
  acc?: string;
  index?: number;
  siteConfig: any;
}): string {
  try {
    const currentFlight = flights[index];
    const tech_stops = currentFlight?.tech_stops;

    if (index >= flights.length - 1) {
      return (
        acc +
        `-- ${getFromToText(currentFlight, general_t, moment, siteConfig, index+1)} ${getTechStopsText({
          amount: tech_stops,
          searchDatePicker_t
        })}`
      );
    }
    const nextFlight = flights[index + 1];
    const currentArrival = moment(currentFlight?.arrival_date * 1000).utc();
    const nextDeparture = moment(nextFlight?.departure_date * 1000).utc();

    const waitTime = moment.duration(nextDeparture.diff(currentArrival));
    const waitTimeHours = Math.floor(waitTime.asHours());
    const waitTimeMinutes = waitTime.minutes();
    const waitType =
      waitTimeHours < 3
        ? searchDatePicker_t.short_wait
        : searchDatePicker_t.long_wait;

    // eslint-disable-next-line max-len
    const airportWaitTimeText = `${waitType}, ${waitTimeHours} ${
      general_t.h
    } ${waitTimeMinutes} ${general_t.min} ${
      searchDatePicker_t.connect_in_airport
    }${getTechStopsText({ amount: tech_stops, searchDatePicker_t })}`;

    const segmentText = `-- ${getFromToText(
      currentFlight,
      general_t,
      moment,
     siteConfig,
        index +1
    )}\n\n( ${airportWaitTimeText} )\n\n`;

    return getFlightsArrStopAsText({
      flights,
      general_t,
      searchDatePicker_t,
      moment,
      acc: acc + segmentText,
      index: index + 1,
      siteConfig,
    });
  } catch (error) {
    console.log('123 getFlightsArrStopAsText catch error: ', error);
    return '';
  }
}
