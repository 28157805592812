import { FaLink, FaSms, FaWhatsapp } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import React from 'react';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
// @ts-ignore
import shareIcon from '../../assets/share.png';
import { AgentActionTypes } from '../AgentActions';
import { Product } from '../../ShoppingCartSliceV2';
import moment from 'moment';
import { MainProductType } from '../../interfaces';
import './agentActionShare.less';
import { message } from 'antd';
import { TenantTsType } from '@skygroup/shared/constants/tenantTs';
import {
  getFlightsArrStopAsText,
  getTechStopsText,
} from './AgentActionShareHelpers';
import {UnitOption} from "../../../AttractionPage/AttractionTypes";

interface Props {
  setSelectedAction: React.Dispatch<
    React.SetStateAction<AgentActionTypes | null>
  >;
}

const AgentActionShare = ({ setSelectedAction }: Props) => {
  const {
    shoppingCartTranslations: t,
    general: general_t,
    searchDatePicker: searchDatePicker_t,
    boards: t_boards,
  } = useSelector((data: RootState) => data.config.translations);
  const { ShoppingCartV2 } = useSelector(
    (data: RootState) => data.shoppingCartV2
  );
  const { siteConfig } = useSelector((data: RootState) => data.config.config);


  function handleFormatPax(pax: any, general_t: any) {

    let txt= "";
    if (!pax) {
      return "";
    }

    if (pax.adult) {
      txt += `${pax.adult} ${general_t.adults}`;
    }

    if (pax.child) {
      txt += `, ${pax.child} ${general_t.children}`;
    }
    if (pax.infant) {
      txt += `, ${pax.infant} ${general_t.infants}`;
    }
    return txt;
  }

  function handleFormatRoom({
    product,
    dashPrefix,
  }: {
    product: Product;
    dashPrefix: string;
  }) {
    let txt = '';
    const board =
      product.product_data?.price?.board ?? product.product_data.board ?? '';
    const boardText = `${general_t.base} ${t_boards?.[board] ?? board}`;
    const firstRoom = {
      adult: product.product_data?.adult || product.product_data.price?.adult,
      child: product.product_data?.child || product.product_data.price?.child,
      infant:
        product.product_data?.infant || product.product_data.price?.infant,
    };
    const allRooms = product.product_data.multi_room_guests
      ? [firstRoom, ...product.product_data.multi_room_guests]
      : [firstRoom];

    allRooms.map((pax) => {
      txt += `${dashPrefix} ${searchDatePicker_t.room} ${product.name}\n`;

      txt += `${dashPrefix}- ${handleFormatPax(pax, general_t)}`;
      txt += ' - ' + boardText;
      txt += `\n\n`;
    });

    return txt;
  }

  function handleFormatFlights({
    product,
    dashPrefix,
  }: {
    product: Product;
    dashPrefix: string;
  }) {
    let txt = '';
    const flight =
      product.product_data.flightFrom ?? product.product_data.flightBack;
    const tech_stops = flight.length === 1 && flight?.[0]?.tech_stops;

    const isSuitCaseIncluded = flight[0].baggage.is_include_suitcase;
    const isTrollyIncluded = flight[0].baggage.is_include_trolly;

    let hasAdditionalTrolly = 0;
    let hasAdditionalSuitCase = 0;
    let isRoundTripSuitcase = false;

    let suitcaseText = '';
    product.subProducts?.forEach((subProduct) => {
      switch (subProduct.type) {
        case MainProductType.Suitcase:
          if (subProduct.product_data?.is_round_trip) {
            isRoundTripSuitcase = true;
          }
          hasAdditionalSuitCase = subProduct.related_guest_indexes.length;
          break;
        case MainProductType.Trolly:
          hasAdditionalTrolly = subProduct.related_guest_indexes.length;
          break;
      }
    });
    if (isSuitCaseIncluded) {
      suitcaseText += general_t.suitcaseIsIncluded;
      if (hasAdditionalSuitCase > 0) {
        suitcaseText += ' + ';
        suitcaseText += general_t.additionalSuitcases.replace(
          'X',
          `${hasAdditionalSuitCase}`
        );
      }
    } else {
      if (hasAdditionalSuitCase > 0) {
        suitcaseText += general_t.additionalSuitcases.replace(
          'X',
          `${hasAdditionalSuitCase}`
        );
        if (isRoundTripSuitcase) {
          suitcaseText += " (" + general_t.includeReturnSuitcase +") ";
        }
      } else {
        suitcaseText += general_t.suitcaseIsNotIncluded;
      }
    }

    suitcaseText += ', ';

    if (isTrollyIncluded) {
      suitcaseText += general_t.trollyIsIncluded;
      if (hasAdditionalTrolly > 0) {
        suitcaseText += ' + ';
        suitcaseText += general_t.additionalTrolly.replace(
          'X',
          `${hasAdditionalTrolly}`
        );
      }
    } else {
      if (hasAdditionalTrolly > 0) {
        suitcaseText += general_t.additionalTrolly.replace(
          'X',
          `${hasAdditionalTrolly}`
        );
      } else {
        suitcaseText += general_t.trollyIsNotIncluded;
      }
    }

    let transferFlightText = '';
    let numOfStops = 0;

    if (product?.product_data?.flightFrom?.length > 1) {
      const flights = product.product_data.flightFrom;
      numOfStops = flights.length;
      transferFlightText += getFlightsArrStopAsText({
        flights,
        general_t,
        searchDatePicker_t,
        moment,
        siteConfig,
      });
    }
    if (product?.product_data?.flightBack?.length > 1) {
      const flights = product.product_data.flightBack;
      numOfStops = flights.length;
      transferFlightText += getFlightsArrStopAsText({
        flights,
        general_t,
        searchDatePicker_t,
        moment,
        siteConfig,
      });
    }
    if (product?.product_data?.flightInfo?.length > 1) {
      const flights = product.product_data.flightInfo;
      numOfStops = flights.length;
      transferFlightText += getFlightsArrStopAsText({
        flights,
        general_t,
        searchDatePicker_t,
        moment,
        siteConfig,
      });
    }

    txt += `${dashPrefix} ${
        product.type == MainProductType.oneWayFlight
            ? general_t.flight
            : product.product_data.flightFrom
                ? general_t.outboundFlight
                : general_t.returnFlight
    } ${flight[0].from.name} → ${flight[flight.length - 1].to.name}`;

    // if we have flight with stops:
    if (numOfStops > 0) {
      txt += ' (' + (numOfStops - 1) + ' ' + searchDatePicker_t.stops + ')\n';
      if (suitcaseText) {
        txt +=suitcaseText +"\n\n";
      }
      txt += transferFlightText;
    } else {

      txt += `\n${flight[0].airline.name}  ${flight[0].airline.id}-${flight[0].flight_number}
${moment(flight[0].departure_date * 1000).utc().format(siteConfig.dateFormat + ' HH:mm')}\n`;

      if (suitcaseText) {
        txt +=suitcaseText;
      }
    }

    if (tech_stops && tech_stops > 0)  {
      txt += "\n" + getTechStopsText({amount: tech_stops,searchDatePicker_t});
    }

    txt +='\n\n';

    return txt;
  }

  let lastRoomDate = '';

  function formatProduct(product: Product, depth: number, parentProduct?: Product) {
    let result = '';
    let customDateFormat = 'ddd, DD MMM YY';
    let customWeekDayName = 'dddd';
    const dashPrefix = '-'.repeat(depth);

    switch (product.type) {
      case MainProductType.Package:
        result += `${dashPrefix} ${product.name ?? t.package}, ${
          product.product_data.nights
        } ${t.nights}, ${moment(product.product_data.dates.start * 1000)
          .utc()
          .format(siteConfig.dateFormat)} - ${moment(
          product.product_data.dates.end * 1000
        )
          .utc()
          .format(siteConfig.dateFormat)}\n`;
        break;
      case MainProductType.Hotel:
        result += `${dashPrefix} ${general_t.hotel} ${product.name}\n`;
        break;
      case MainProductType.Room:
        // parentProduct if hotel & if it has identifier - it means this is not package
        if (parentProduct?.identifier && product.date_start && product.date_end) {
          const dateStart = moment(product.date_start * 1000).utc();
          const dateEnd = moment(product.date_end * 1000).utc();
          const currentRoomDate = ` ${dateStart.format(customWeekDayName)} - ${dateEnd.format(customWeekDayName)}, ${dateStart.format(siteConfig.dateFormat)} - ${dateEnd.format(siteConfig.dateFormat)} \n`;

          // to add it only 1 time if we have more than 1 room
          if (lastRoomDate != currentRoomDate) {
            lastRoomDate = currentRoomDate;
            result += currentRoomDate;
          }
        }
        result += handleFormatRoom({ product, dashPrefix });

        break;
      case MainProductType.Flight:
      case MainProductType.oneWayFlight:
        result += handleFormatFlights({ product, dashPrefix });
        break;
      case MainProductType.oneWayFlights:
      case MainProductType.Flights:
        // let numOfStops = 0;
        // if (product?.product_data?.flightsInfo?.numOfStops) {
        //   for (const key in product?.product_data?.flightsInfo?.numOfStops) {
        //     numOfStops += product?.product_data?.flightsInfo?.numOfStops[key];
        //   }
        // }
        // const stopTitle = numOfStops + ' ' + searchDatePicker_t.stops;

        result += `${dashPrefix} ${general_t.bundleFlight}`;

        result += " - " + handleFormatPax(product.product_data.pax, general_t) + "\n";

        product.subProducts?.forEach((subProduct) => {
          formatProduct(subProduct, depth + 1);
        });
        break;
      case MainProductType.Activity:
        result += `${dashPrefix} ${general_t.attraction}: ${product.name}\n`;
        break;
      case MainProductType.Attraction:
        result += `${dashPrefix} - ${product.name} \n`;
        break;
      case MainProductType.AttractionTicket:
        debugger
        result += `${dashPrefix} - ${product?.name} ${
          product?.date_start
            ? ', ' +
              moment(product?.date_start * 1000)
                .format(siteConfig.dateFormat)
            : ''
        }\n`;

        const unitOptions = product?.product_data?.productDetails?.availability?.unitOptions;
        if (unitOptions?.length) {
          const pax: string[] = [];
          unitOptions.forEach((unit: UnitOption) => {
            if (unit?.unitQuantity) {
              pax.push(unit.unitQuantity + " x " + unit.label);
            }
          });
          result+= ( pax.join(", ") ) + "\n";
        }
        break;
      default:
        break;
    }

    if (product.subProducts && product.subProducts.length > 0) {
      product.subProducts.forEach((subProduct) => {
        result += formatProduct(subProduct, depth + 1, product);
      });
    }
    return result;
  }

  function formatAllProducts(shoppingCartV2: any) {
    let result = '';
    shoppingCartV2.products.forEach((product: Product) => {
      result += formatProduct(product, 0);
    });
    result +=
      general_t.total +
      ' ' +
      Math.round(shoppingCartV2.total_price) +
      String.fromCharCode(160) +
      shoppingCartV2.currency_sign +
      ` (${general_t.as_of_today}) \n`;
    if (shoppingCartV2.orderSecret) {
      result +=
        `${general_t.link_to_the_offer} \n` +
        window.location.origin +
        `/order/manage?id=${shoppingCartV2.orderSecret}`;
    }
    return result;
  }

  const handleCopy = () => {
    const formattedProductInfo = formatAllProducts(ShoppingCartV2);
    navigator.clipboard
      .writeText(formattedProductInfo)
      .then((r) => {
        message.success(general_t.the_quote_has_been_copied_successfully);
      })
      .catch((e) => {
        window.parent.postMessage(
          { action: 'copy', text: formattedProductInfo },
          '*'
        );
      });
  };

  const handleWhatsappClick = () => {
    const formattedProductInfo = formatAllProducts(ShoppingCartV2);
    // const phone = customerInfo.phone.slice(1);
    const phone = ShoppingCartV2?.customerInfo?.customer_phone?.slice(1);
    const prefixSymbol = '+'; // sync with nir on this one
    const countryCode = '972'; // sync with nir on this one
    const link = `whatsapp://send?text=${encodeURIComponent(
      formattedProductInfo
    )}&phone=${prefixSymbol}${countryCode}${phone}`;
    window.open(link);
  };

  return (
    <div className="agent-action-share">
      <div className="share-header">
        <span>
          <img src={shareIcon} />
          <div className="text">
            <span>{t.findQuoteFor}</span>
            <span>{t.linkIsGive}</span>
          </div>
        </span>
        <IoClose size={20} onClick={() => setSelectedAction(null)} />
      </div>

      {ShoppingCartV2?.customerInfo?.customer_phone && (
        <div className="option" onClick={handleWhatsappClick}>
          <FaWhatsapp size={20} color="#15bd25" />
          <span>{t.sendByWhatsApp}</span>
        </div>
      )}
      <div className="option" onClick={handleCopy}>
        <FaLink size={20} color="#0062ff" />
        <span>{t.copyText} </span>
      </div>
      {ShoppingCartV2?.customerInfo?.customer_phone && (
        <div className="option">
          <FaSms size={20} color="#000" />
          <span>{t.sendBySMS}</span>
        </div>
      )}
    </div>
  );
};

export default AgentActionShare;
