import React, { useState, useEffect } from 'react';
import './flightButton2.less';
import { Button, Modal } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import useTranslationForPersonType from '../../hooks/useGetCorrectName';
import {
  FightTermsType,
  FlightsInfo,
  FlightType,
} from '../../interfaces/flightInterface';
import useGetGrossPrice from '../../hooks/useGetGrossPrice';
import { IoInformationOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import AgentProductTerms from '../AgentCommission/AgentProductTerms';
import FlightTerms from '../AgentCommission/FlightTerms';

import { TenantTsType } from '@skygroup/shared/constants/tenantTs';
import { Buffer } from 'buffer';
import { MainProductType } from '../../modules/ShoppingCart/interfaces';

interface Props {
  flightPrice: {
    adult: number;
    currency_sign: string;
    infant?: number;
    child?: number;
    avg_price?: number;
    total: number;
  };
  handlePurchase?: (
    type: FlightType,
    f1: string,
    returnLink?: boolean
  ) => void | string;
  type?: FlightType;
  f1?: string;
  pax: {
    adult: number;
    child: number;
    infant: number;
  };
  selectedTrolly?: {
    [key: string]: boolean;
  };
  selectedSuitcase?: {
    [key: string]: boolean;
  };
  suitcasePrice?: number;
  trollyPrice?: number;
  viewPage?: boolean;
  identifier?: string;
  suggested_commission: number;
  buttonText?: string;
  flightsTerms?: any;
  description?: string;
  isPreviewMode?: boolean;
  showProductPrice?: boolean;
}

const FlightButton2 = ({
  flightPrice,
  handlePurchase,
  type,
  f1,
  pax,
  selectedTrolly,
  selectedSuitcase,
  suitcasePrice,
  trollyPrice,
  viewPage = false,
  identifier,
  suggested_commission,
  buttonText,
  flightsTerms,
  isPreviewMode,
  showProductPrice = true,
}: Props) => {
  const { flightButton: t, agentCommission: agentCommissionT } = useSelector(
    (data: RootState) => data.config.translations
  );
  const [modalOpenData, setModalOpenData] = useState<any>(null);
  const [showTermsWithApiIdentifiers, setShowTermsWithApiIdentifiers] =
    useState([]);

  const totalTrollyPrice = Object.keys(selectedTrolly || {}).reduce(
    (acc, curr) => {
      if (selectedTrolly && selectedTrolly[curr]) {
        return acc + (trollyPrice || 0);
      }
      return acc;
    },
    0
  );
  const totalSuitcasePrice = Object.keys(selectedSuitcase || {}).reduce(
    (acc, curr) => {
      if (selectedSuitcase && selectedSuitcase[curr]) {
        return acc + (suitcasePrice || 0);
      }
      return acc;
    },
    0
  );

  const totalPrice =
    flightPrice.adult * (pax.adult ?? 0) +
    (flightPrice.child ?? 0) * (pax.child ?? 0) +
    (flightPrice.infant ?? 0) * (pax.infant ?? 0) +
    totalSuitcasePrice +
    totalTrollyPrice;

  if (!flightPrice.total) {
    flightPrice.total = totalPrice;
  }

  const totalPaxNoInfants =
    (pax.adult ? Number(pax.adult) : 0) + (pax.child ? Number(pax.child) : 0);

  const getPersonTypeTranslation = useTranslationForPersonType();

  const { ShoppingCartV2 } = useSelector(
    (state: RootState) => state.shoppingCartV2
  );

  const isButtonDisalbed = ShoppingCartV2?.products?.find((product) => {
    if (
      product.type === MainProductType.oneWayFlights &&
      product?.subProducts?.length === 2
    ) {
      const twoOneWayIdentifier = `${product?.subProducts[0].identifier}&${product?.subProducts[1].identifier}`;
      return twoOneWayIdentifier === identifier;
    }
    return product.identifier === identifier;
  });

  const getGrossPrice = useGetGrossPrice();

  return (
    <div className="flight-button-2">
      {flightsTerms &&
        Object.keys(flightsTerms).length > 0 &&
        Object.entries(flightsTerms).map(
          ([key, val]: [key: string, val: any]) => {
            if (val?.[0]?.identifier && val?.[0]?.ts !== TenantTsType.ALP) {
              return null;
            }

            return (
              <div
                className="info"
                key={key}
                onClick={() =>
                  setModalOpenData(() =>
                    val?.[0]?.identifier ? val?.[0] : val
                  )
                }
              >
                <span>
                  <IoInformationOutline size={40} />
                </span>
                <span>
                  {t.cardConditions}{' '}
                  {Object.keys(flightsTerms).length > 1 ? key : ''}
                </span>
              </div>
            );
          }
        )}

      {!isPreviewMode && (
        <>
          {showProductPrice && <div className="price-wrapper">
            <div className="average-price-wrapper">
              <div className="single-price-text">{t.pricePerPassenger}</div>
              <div className="single-price">
                {getGrossPrice(
                  flightPrice.total / totalPaxNoInfants,
                  suggested_commission
                )}
                {String.fromCharCode(160)}
                {flightPrice.currency_sign}
              </div>
            </div>
            <div className="total-price-wrapper">
              <div className="number-of-passengers">
                {pax.adult > 0 && (
                  <div>
                    {pax.adult}{' '}
                    {getPersonTypeTranslation('adult', Number(pax.adult))}
                  </div>
                )}
                {Number(pax.child) > 0 && (
                  <div>
                    {pax.child}{' '}
                    {getPersonTypeTranslation('child', Number(pax.child))}
                  </div>
                )}
                {Number(pax.infant) > 0 && (
                  <div>
                    {pax.infant}{' '}
                    {getPersonTypeTranslation('infant', Number(pax.infant))}
                  </div>
                )}
              </div>
              <div className="total">
                {getGrossPrice(flightPrice.total, suggested_commission)}{' '}
                {String.fromCharCode(160)}
                {flightPrice?.currency_sign}
              </div>
            </div>
          </div>}
          <div>
            {
              viewPage ? (
                <Button
                  className="btn-style-5"
                  size="large"
                  onClick={handlePurchase as any}
                  disabled={!!isButtonDisalbed}
                >
                  {t.addToCart}
                </Button>
              ) : (
                <Button
                  className="btn-style-5"
                  size="large"
                  disabled={!!isButtonDisalbed}
                  onClick={() => {
                    type && f1 && handlePurchase && handlePurchase(type, f1);
                  }}
                >
                  {buttonText ?? t.continueToOrder}
                </Button>
              )
              // TODO: fix scenario when we render one ways - that do the select flight
              //     (
              //   <Link
              //     to={
              //       type && f1 && handlePurchase
              //         ? (handlePurchase(type, f1, true) as string)
              //         : ''
              //     }
              //     target={'_blank'}
              //   >
              //     <Button
              //       className="btn-style-5"
              //       size="large"
              //       disabled={!!isButtonDisalbed}
              //       onClick={undefined}
              //     >
              //       {buttonText ?? t.continueToOrder}
              //     </Button>
              //   </Link>
              // )
            }
          </div>
        </>
      )}

      <Modal
        open={!!modalOpenData}
        onCancel={() => setModalOpenData(null)}
        footer={null}
        destroyOnClose
        className={`terms-info-modal`}
      >
        {modalOpenData && !modalOpenData?.identifier && identifier && (
          <FlightTerms identifier={identifier} flightsTerms={modalOpenData} />
        )}
        {modalOpenData &&
          modalOpenData?.ts === TenantTsType.ALP &&
          modalOpenData?.identifier && (
            <AgentProductTerms
              identifier={modalOpenData?.identifier}
              description={modalOpenData?.description}
            />
          )}
      </Modal>
    </div>
  );
};

export default FlightButton2;
