import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useQuery } from '@tanstack/react-query';
import apiClient from '@skygroup/shared/API/apiClient';
import endpoints from '../../utils/endpoints';
import { useLocation } from 'react-router-dom';
import {
  FlightType,
  FlightViewResponse,
} from '../../interfaces/flightInterface';
interface ApiError {
  status: number;
  message: string;
  code: number;
}

const fetchFlightView = async (identifier:string,flightType:FlightType, adult:number | string, child:number | string, infant:number | string) => {
  const body = {
    flightType,
    f1: identifier,
    adult: Number(adult),
    child: Number(child),
    infant: Number(infant),
  };

  try {
    const response = await apiClient.post(`${endpoints.FLIGHTS.VIEW_FLIGHT_ALP}`, body);
    if (flightType === 'one_way') {
      if(response.data === "") {
        throw new Error('error');
      }

      if (typeof response.data === 'string') {
        throw response.data;
      }

      if(response.data.flightsInfo){
        return response.data;
      }
      return {
        flightsInfo: {
          flightFrom: response.data.flight,
          ...response.data,
        },
        pax: response.data.pax,
      };
    }

    if (typeof response.data === 'string') {
      throw response.data;
    }
    return response.data;
  } catch (error) {
    throw new Error('error');
  }
};
export const useViewFlightApiMs = () => {
  const location = useLocation();

  const pathSegments = location.pathname.split('/').filter(Boolean);

  // hot fix
  const flightType = pathSegments[2] as FlightType;
  const identifiers = pathSegments[3] ? pathSegments[3].split(",") : []; // Split identifiers into an array
  const { adult, child, infant } = useSelector(
    (data: RootState) => data.search.searchParams
  );

  const flightViews = useQuery<FlightViewResponse[], ApiError>(
    ['flight-view-ms', identifiers, adult, child, flightType, infant],
    async () => {
      const allResponsePromises = identifiers.map(identifier =>
          fetchFlightView(identifier,flightType, adult, child, infant )
      );
      const results = await Promise.all(allResponsePromises);
      return results;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: false,
      keepPreviousData: true,
    }
  );

  return flightViews;
};

const useGetFlightAdditionalFares = (tsBookingDataFromProp?:any, identifierFromProp?:string) => {

  const {data:selectedFlight}:any = useViewFlightApiMs();
  const pathSegments = location.pathname.split('/').filter(Boolean);
  const flightType = pathSegments[2] as FlightType;
  const identifiers = pathSegments[3] ? pathSegments[3].split(",") : [];

  const flightIdentifier = identifierFromProp ?? identifiers[0];
  const ts_booking_data =   tsBookingDataFromProp ?? selectedFlight?.[0]?.flightsInfo?.ts_booking_data
  const isEnabled = !!(ts_booking_data && flightIdentifier);

  const flightViews = useQuery<any>(
  ['additional-fares', flightIdentifier],
    async () => {
      const results = await apiClient.post(
        `${endpoints.FLIGHTS.VIEW_ADDITIONAL_FARES}`,
        {
          flightType: flightType,
          identifier: flightIdentifier,
          ts_booking_data:ts_booking_data,
        }
      );
        if (results.data?.status == "error") {
            console.log(results.data);
            return [];
        } else {
            return Object.values(results.data?.data);
        }
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: isEnabled,
      keepPreviousData: true,
      staleTime:Infinity
    }
);

return flightViews;
};
export default useGetFlightAdditionalFares;