import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import useGetFlightData from '../../Checkout/Hooks/useGetFlightData';
import { MainProductType } from '../interfaces';
import {
  CommissionType,
  handleAddBaggageToShoppingCart,
  Product,
} from '../ShoppingCartSliceV2';
import useShoppingCartNotification from './useShoppingCartNotification';

const useHandleManageBaggage = (product: Product) => {
  const dispatch = useDispatch();
  const createNotification = useShoppingCartNotification();
  const { agent_commission, ShoppingCartV2 } = useSelector(
    (data: RootState) => data.shoppingCartV2
  );

  const staticI = ShoppingCartV2.products.findIndex((p) => {
    return p.identifier === product.identifier;
  });

  const { flightBackBaggage, flightFromBaggage } = useGetFlightData({
    flightSubProduct: product.subProducts?.filter(
      (subProduct) =>
        subProduct.type === MainProductType.Flight ||
        subProduct.type === MainProductType.oneWayFlight
    ),
    relatedGuestIndex: product.related_guest_indexes[0],
  });

  const isRoundTrip =
    flightFromBaggage?.is_round_trip || flightBackBaggage?.is_round_trip;

  const handleAddBaggage = (
    e: any,
    direction: number,
    type: MainProductType.Trolly | MainProductType.Suitcase,
    price: number,
    rgi: number
  ) => {
    const isOneWayFlight =
      product.subProducts?.[0]?.type === MainProductType.oneWayFlight;

    const adjustedPrice =
      agent_commission?.type === CommissionType.Percentage
        ? price / (1 - Number(agent_commission?.percentage ?? 0) / 100)
        : price;

    dispatch(
      handleAddBaggageToShoppingCart({
        e,
        direction,
        type,
        staticI,
        relatedGuestIndex: rgi,
        price: adjustedPrice,
        netPrice: price,
        isRoundTrip: isRoundTrip,
        isOneWayFlight,
      })
    );
    createNotification({});
  };

  const flightFromSuitcaseSaleAllowed = flightFromBaggage?.enable_sale_suitcase;
  const flightFromTrollySaleAllowed = flightFromBaggage?.enable_sale_trolly;
  const flightBackSuitcaseSaleAllowed = flightBackBaggage?.enable_sale_suitcase;
  const flightBackTrollySaleAllowed = flightBackBaggage?.enable_sale_trolly;

  const isShowManageBaggageButton =
    flightFromSuitcaseSaleAllowed ||
    flightFromTrollySaleAllowed ||
    flightBackSuitcaseSaleAllowed ||
    flightBackTrollySaleAllowed;

  return {
    handleAddBaggage,
    isRoundTrip,
    flightBackBaggage,
    flightFromBaggage,
    isShowManageBaggageButton,
  };
};

export default useHandleManageBaggage;
