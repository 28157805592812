import { IoClose } from 'react-icons/io5';
import React from 'react';
import {
  handleAdjustGuestIndexes,
  Product,
  removeAttractionsFromCart,
} from '../../ShoppingCartSliceV2';
import { RootState } from '../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import ProductPreviewComponent from '../../widgets/ProductPreviewComponent';
import { attractionLink } from '../../../../utils/LinkGenerator';
import moment from 'moment';
import PersonSVG from '../../../RoomPricing/assets/PersonSVG';
import Icon from '../../../AttractionPage/Icon';
import {
  PriceUnit,
  PriceUnitLabels,
  UnitOption,
} from '../../../AttractionPage/AttractionTypes';
import { roundUpCurrency } from '@skygroup/shared/utils/roundUpCurrency';
import { currencySignsObj } from '@skygroup/shared/utils/constants';
import { Divider } from 'antd';
import useGetGrossPrice from '../../../../hooks/useGetGrossPrice';

interface Props {
  product: any; // Product;
  identifier: string;
  isCheckoutPage: boolean;
  showProductPrice?: boolean;
}

const ShoppingCartAttraction = ({
  product,
  identifier,
  isCheckoutPage,
  showProductPrice = true,
}: Props) => {
  const dispatch = useDispatch();
  const { siteConfig } = useSelector((data: RootState) => data.config.config);
  const { shoppingCartTranslations: t, general: general_t } = useSelector(
    (data: RootState) => data.config.translations
  );
  const { ShoppingCartV2 } = useSelector(
    (state: RootState) => state.shoppingCartV2
  );
  const getPrice = useGetGrossPrice();

  function generateLink(obj: any) {
    const startDate = moment(
      (obj?.date_start || obj?.subProducts?.[0]?.date_start) * 1000
    ).utc();
    const endDate = moment(
      (obj?.date_end || obj?.subProducts?.[0]?.date_end) * 1000
    ).utc();

    const formattedStartDate = startDate.format(siteConfig.dateFormat);
    const formattedEndDate = endDate.format(siteConfig.dateFormat);
    const link = `?from=${encodeURIComponent(
      formattedStartDate
    )}&to=${encodeURIComponent(formattedEndDate)}`;

    return link;
  }

  const handleDeleteAttraction = (
    productIdentifier: string,
    subProductId: string
  ) => {
    dispatch(
      removeAttractionsFromCart({
        attractionIdentifier: productIdentifier,
        attractionTicketId: subProductId,
      })
    );

    if (product?.subProducts?.length < 2) {
      // if this condition is met, it means its a final ticket of the attraction,
      // and upon removing it we need to update g_indexes of the shopping cart products
      dispatch(handleAdjustGuestIndexes(product));
    }
  };

  return (
    <div key={identifier} className={'product-top attraction-product'}>
      <div className={'product-title'}>
        <ProductPreviewComponent
          productPath={attractionLink(identifier, generateLink(product))}
          label={product.name}
          componentClassName={'isColumn'}
        />
      </div>

      <div
        className={'sub-products-title'}
      >{`${t?.activities} ${t.details}`}</div>

      <div className="subProduct-wrapper">
        {!!product?.subProducts?.length &&
          product.subProducts.map((subProduct: Product, ii: number) => {
            const subProductData =
              subProduct?.product_data?.productDetails?.availability;

            return (
              <div className="subProduct-row" key={`${identifier}_${subProductData?.id}`}>
                {ii > 0 && <Divider dashed />}
                {subProduct?.name && (
                  <div>
                    <b>{general_t.ticketType}: </b>
                    <span className="title">{subProduct?.name}</span>
                  </div>
                )}

                {(subProduct?.date_start || product?.date_start) && (
                  <div>
                    <b>{general_t.date}: </b>{' '}
                    <span className="title">
                      {moment(
                        (subProduct?.date_start || product?.date_start) * 1000
                      ).format(siteConfig.dateFormat)}
                    </span>
                  </div>
                )}

                {product?.product_data?.productInfo?.duration && (
                  <div>
                    <b>{general_t.duration}: </b>{' '}
                    <span className="title">
                      {product?.product_data?.productInfo?.duration}
                    </span>
                  </div>
                )}

                <div className={'last-row'}>
                  <div className="product-guests">
                    {subProductData?.unitOptions?.length &&
                      subProductData?.unitOptions?.map(
                        (unitOpt: UnitOption) => {
                          if (unitOpt?.restrictions?.maxQuantity < 1) {
                            return null;
                          }

                          return (
                            <div
                              className="icon-box"
                              key={`${identifier}_${subProductData?.id}_${unitOpt.label}`}
                            >
                              <Icon
                                type={
                                  unitOpt?.priceUnitType ===
                                  PriceUnit?.perPerson
                                    ? (unitOpt.label as any)
                                    : unitOpt?.priceUnitType
                                }
                              />
                              <b>{`x ${unitOpt.unitQuantity}`}</b>
                            </div>
                          );
                        }
                      )}
                  </div>
                  {!ShoppingCartV2.tp_id && subProduct?.total_price && (
                    <div className="price-box">
                      {showProductPrice && (
                        <span>{`${
                          // roundUpCurrency(subProduct?.total_price, 2)
                          getPrice(subProduct?.total_price, 2)
                        } ${
                          currencySignsObj?.[
                            product?.product_data?.productInfo?.priceCurrency?.toLowerCase() as keyof typeof currencySignsObj
                          ] ?? product?.product_data?.productInfo?.priceCurrency
                        }`}</span>
                      )}
                      {!isCheckoutPage && (
                        <IoClose
                          size={15}
                          className="delete-product"
                          onClick={() =>
                            handleDeleteAttraction(identifier, subProduct.id)
                          }
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ShoppingCartAttraction;
