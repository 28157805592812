const endpoints = {
  GET_AUTH_TOKEN: '/get-auth-token',
  GET_HOTELS_INDEX: '/supplier/hotel/index',
  GET_AREA_LIST: '/supplier/destination/dropdown',
  GET_FORM_RULES: '/supplier/hotel/rules',
  GET_TUTORIAL_RULES: '/supplier/tutorial/rules',
  CREATE_HOTEL: '/supplier/hotel/create',
  UPDATE_HOTEL: '/supplier/hotel/update',
  GET_HOTEL_BY_ID: '/supplier/hotel/view/',
  GET_HOTEL_PROPERTIES: '/supplier/hotel/allPropsAndFacilities/',
  UPDATE_IMAGE_METADATA: '/supplier/hotel/updateImages?id=',
  GET_DESTINATIONS_INDEX: '/supplier/destination/index',
  CREATE_DESTINATION: '/supplier/destination/create',
  UPDATE_DESTINATION: '/supplier/destination/update',
  GET_DESTINATION: '/supplier/destination/view',
  GET_PROMOTIONS_INDEX: '/supplier/promotion/index',
  CREATE_PROMOTION: '/supplier/promotion/create',
  UPDATE_PROMOTION: '/supplier/promotion/update',
  GET_PROMOTION: '/supplier/promotion/view',
  GET_COURSE_INDEX: '/supplier/course/details',

  DATE_RANGE_PRICE: {
    INDEX: '/supplier/date-price-range/index',
    GET: '/supplier/date-price-range/view',
    UPDATE: '/supplier/date-price-range/update',
    CREATE: '/supplier/date-price-range/create',
    DELETE: '/supplier/date-price-range/delete',
  },

  PRICE: {
    INDEX: '/supplier/price/index',
    SAVE: '/supplier/price/save',
  },

  // AFFILIATE
  GET_AFFILIATE_INDEX: '/supplier/affiliate/index',
  GET_AFFILIATE: '/supplier/affiliate/view',
  UPDATE_AFFILIATE: '/supplier/affiliate/update',
  DELETE_AFFILIATE: '/supplier/affiliate/delete',
  CREATE_AFFILIATE: '/supplier/affiliate/create',
  // Agency
  GET_AGENCY_INDEX: '/supplier/agency/index',
  GET_AGENCY: '/supplier/agency/view',

  UPDATE_AGENCY: '/supplier/agency/update',
  DELETE_AGENCY: '/supplier/agency/delete',
  CREATE_AGENCY: '/supplier/agency/create',
  AGENCY_UPLOAD_FILE: '/supplier/agency/agencyDocUpload',
  GET_REVENUE_TOTALS: '/supplier/agency/revenueTotals',
  GET_ORDERS_FOR_REVENUE_REPORTS: '/supplier/agency/OrdersForRevenueReports',
  GET_AGENCY_REG_COMPLETED: '/supplier/subscription/agencyRegistrationCompleted',
  GET_AGENCY_SUBSCRIPTION: '/supplier/subscription/getCardAndActiveSubscription',
  GET_AGENCY_PLANS: '/supplier/subscription/plans',
  CANCEL_SUBSCRIPTION_PLAN: '/supplier/subscription/cancel',
  UPDATE_AGENCY_PLANS: '/supplier/subscription/updatePlan',
  GET_AGENCY_ACCOUNTANT_INFO: '/supplier/agency/accountantInfo',
  UPDATE_AGENCY_ACCOUNTANT_INFO: '/supplier/agency/updateAccountantInfo',
  GET_UPGRADE_REQUEST:"/supplier/subscription/upgradeRequest",

  AGENCY_BALANCE: {
    CREATE_AGENCY_BALANCE: '/supplier/agency-balance/addDeposit',
    UPDATE_AGENCY_BALANCE: '/supplier/agency-balance/updateDeposit',
    GET_AGENCY_BALANCE: '/supplier/agency-balance/viewDeposit',
  },

  AGENT_USER: {
    INDEX: '/supplier/agent-user/index',
    GET: '/supplier/agent-user/view',
    UPDATE: '/supplier/agent-user/update',
    CREATE: '/supplier/agent-user/create',
    // DELETE: '/supplier/agent-user/delete',
  },
  // Agent-REGISTRATION
  AGENT_INFO: '/supplier/agent-registration/tenant-info',
  REGISTER_AGENT: '/supplier/agent-registration/create-agency',

  // Airline
  GET_AIRLINE_INDEX: '/supplier/airline/index',
  GET_AIRLINE: '/supplier/airline/view',
  UPDATE_AIRLINE: '/supplier/airline/update',
  DELETE_AIRLINE: '/supplier/airline/delete',
  CREATE_AIRLINE: '/supplier/airline/create',
  AIRLINE_RULES: 'supplier/airline/rules',
  // Flight
  GET_FLIGHT_INDEX: '/supplier/flight/index',
  GET_FLIGHT_SERIES_INDEX: '/supplier/flight-series/index',
  GET_FLIGHT_SERIES_VIEW: '/supplier/flight-series/view',
  GET_FLIGHT: '/supplier/flight/view',
  UPDATE_FLIGHT: '/supplier/flight/update',
  DELETE_FLIGHT: '/supplier/flight/delete',
  CREATE_FLIGHT: '/supplier/flight/create',
  CREATE_FLIGHT_SERIES: '/supplier/flight-series/create',
  UPDATE_FLIGHT_SERIES: '/supplier/flight-series/update',
  DELETE_FLIGHT_SERIES: '/supplier/flight-series/delete',
  FLIGHT_RULES: 'supplier/flight/rules',
  FLIGHT_SERIES_RULES: 'supplier/flight-series/rules',
  GET_IMPORT_HISTORY_INDEX: '/supplier/flight-passenger/importHistory',
  GET_IMPORT_HISTORY: '/supplier/flight-passenger/importHistoryView',
  GET_FLIGHT_CLASS_INDEX:'/supplier/flight-class/index',
  GET_FLIGHT_CLASS:"/supplier/flight-class/view",
  CREATE_FLIGHT_CLASS:"/supplier/flight-class/create",
  UPDATE_FLIGHT_CLASS: "/supplier/flight-class/update",
  DELETE_FLIGHT_CLASS: "/supplier/flight-class/delete",
  FLIGHT_CLASS_RULES: "/supplier/flight-class/rules",

  ADD_CLASS_TO_FLIGHT_SERIES: "/supplier/flight-series/addFlightClass",
  UPDATE_CLASS_TO_FLIGHT_SERIES: "/supplier/flight-series/updateFlightClass",

  // AGENCY PAYMENTS
  GET_AGENCY_PAYMENT_INDEX: '/supplier/agency-payments/index',
  AGENCY_PAYMENT_SEND_MAIL:"supplier/agency-payments/send-proforma",
  CREATE_PROFORMA:"supplier/agency-payments/create-proforma",
  FLIGHT_IMPORT: {
    PARSE_PNL: 'supplier/pnl/parse',
    IMPORT_PASSENGERS: '/supplier/flight-passenger/import',
    RERUN_IMPORT: '/supplier/flight-passenger/RerunImportHistory',
    EXPORT_TO_DCS:'supplier/pnl/export-flight'
  },
  GET_PASSENGERS:'supplier/flight-passenger/index',
  GET_PASSENGER: '/supplier/flight-passenger/view',
  UPDATE_PASSENGER: '/supplier/flight-passenger/update',
  DELETE_PASSENGER: '/supplier/flight-passenger/delete',
  GET_PASSENGER_FORM_RULES: '/supplier/flight-passenger/rules',

  //User
  GET_USER_INDEX: '/supplier/user/index',
  GET_USER: '/supplier/user/view',
  UPDATE_USER: '/supplier/user/update',
  DELETE_USER: '/supplier/user/delete',
  CREATE_USER: '/supplier/user/create',


  //AUTOCOMPLETE
  AUTOCOMPLETE: {
    CITY: '/ms/product-search/autocomplete/city',
    AIRPORT: '/ms/product-search/autocomplete/airports',
    GET_AIRPORT: '/ms/product-search/loacationByIata',
    GET_CITY: '/ms/product-search/city',
    GET_CALENDAR: '/ms/product-search/calendar',
    GET_BY_TS: '/ms/product-search/autocomplete/ts',
    HOTEL:"/ms/product-search/autocomplete/hotel",
    GET_LOCATIONS_BY_FEATURE:"/ms/product-search/locations-by-feature",
  },

  // VIDEO TUTORIAL CRUD
  VIDEO_TUTORIAL: {
    INDEX: '/supplier/tutorial/index',
    CREATE: '/supplier/tutorial/create',
    GET: '/supplier/tutorial/view',
    UPDATE: '/supplier/tutorial/update',
    DELETE: '/supplier/tutorial/delete',
  },
  GET_ALL_TUTORIAL_VIDEO: '/supplier/tutorial/all',
  UPLOAD_TUTORIAL_VIDEO: '/supplier/tutorial/upload',

  // SUPPLIER
  GET_SUPPLIER_INDEX: '/supplier/supplier/index',
  GET_SUPPLIER_RULES: '/supplier/supplier/rules',
  GET_SUPPLIER: '/supplier/supplier/view',
  UPDATE_SUPPLIER: '/supplier/supplier/update',
  DELETE_SUPPLIER: '/supplier/supplier/delete',
  CREATE_SUPPLIER: '/supplier/supplier/create',

  // SUPPLIER_PAYMENTS
  GET_SUPPLIER_PAYMENTS: '/supplier/supplier-payments/index',
  GET_SUPPLIER_INVOICE: '/supplier/supplier-payments/getSupplierInvoice',
  GET_SUPPLIER_INVOICES: '/supplier/supplier-payments/IndexInvoices',
  CREATE_SUPPLIER_PAYMENT: '/supplier/supplier-payments/create',
  UPDATE_SUPPLIER_PAYMENT: '/supplier/supplier-payments/update',
  UPDATE_SUPPLIER_PAYMENT_STATUS: '/supplier/supplier-payments/updatePaymentStatus',
  UPLOAD_SUPPLIER_PAYMENT: '/supplier/supplier-payments/upload',
  SUPPLIER_PAYMENT_VOUCHER: '/ms/booking/supplier-payment-voucher ',
  UPDATE_ACCOUNTANT_READY: '/supplier/supplier-payments/accountantReady',

  GET_HOTEL_ROOMS: '/supplier/room/index',
  CREATE_ROOM: '/supplier/room/create',
  UPDATE_ROOM: '/supplier/room/update',
  GET_ROOM: '/supplier/room/view',
  UPDATE_GALLERY: '/supplier/hotel/updateImages',
  UPLOAD_IMAGE: 'supplier/image/upload',
  GET_GALLERY : 'supplier/image/gallery',
  DELETE_IMAGE: 'supplier/image/delete',
  UPDATE_IMAGE:'supplier/image/update',

  // Order
  GET_ORDER_INDEX: '/supplier/order/index',
  GET_ORDER: '/supplier/order/view',
  POST_ORDER_LOG: 'supplier/order/addLog',

  GET_TRANSACTIONS_INDEX: 'supplier/payment/index',
  POST_PAY_WITH_CREDIT_CARD: 'supplier/payment/payWithToken',
  POST_PAY_WITH_SECURED_TRANSACTION: 'supplier/payment/payWithSecuredTransaction',
  POST_TAKE_CREDIT_LINE_BY_TOKEN: 'b2c/payment/takeCreditLineByToken',
  POST_BANK_TRANSFER: 'supplier/payment/payWithBankTransfer',

  POST_DOC_TO_TRANSACTION: 'b2c/payment/uploadDocToTransaction',
  POST_APPROVE_BANK_TRANSFER: 'supplier/payment/approveBankTransfer',
  POST_UPLOAD_TRANSACTION_FILE: '/supplier/payment/uploadTransactionFile',
  
  GET_ORDER_LOG: 'supplier/order/log',
  GET_ORDER_PRODUCT_TECH_LOG: 'supplier/order/ProductTechLog',
  GET_ORDER_PRODUCT_LOG: 'supplier/order/ProductLog',
  ORDER_SEND_SMS: '/supplier/order/sendSms',
  ORDER_SEND_ORDER_SUMMARY: '/supplier/order/orderSummary',
  ORDER_REASSIGN_ORDER_TO_AGENCY: '/supplier/order/assignOrderToAgency',
  GET_ORDER_FILTER_DROPDOWNS: 'supplier/dictionary/dropdown',

  POST_BOOKING_RESERVATION: '/ms/booking/reservation',
  DELETE_ORDER: '/ms/booking/delete-order',
  REFRESH_RATES: '/ms/booking/refresh-rates',
  // RETRIEVE_PNR_FROM_TS: '/ms/booking/retrieve-pnr-from-ts',
  OP_RETRIEVE_PNR_FROM_TS: '/ms/booking/op-retrieve-pnr-from-ts',
  GET_ORDER_SUMMARY_PDF: '/ms/booking/order-summary',
  RECREATE_PROFORMA: '/supplier/payment/recreateProforma',
  SEND_ORDER_VOUCHER: '/supplier/voucher/sendVouchers',
  ORDER_UPLOAD_VOUCHER: '/supplier/voucher/upload',
  DELETE_ORDER_PRODUCT_VOUCHER: '/supplier/voucher/delete',
  PAYMENT_SEND_MAIL:"/supplier/payment/sendProforma",
  CANCEL_PAYMENT_TRANSACTION: '/supplier/payment/cancelPayment',
  TRANSFER_UNUSED_TRANSACTION_TO_ORDER: '/supplier/payment/transferUnusedTransactionToOrder',
  TRANSFER_PAYMENT_TO_ORDER: '/supplier/payment/transferPaymentToOrder',

  UPDATE_ORDER_CUSTOMER_INFO: 'supplier/customer/update', // is still in use?

  UPDATE_ORDER_PRODUCT_PRICING: '/ms/manage-order/update-order-product-pricing',
  GET_INFANT_DATA: "/ms/manage-order/infant-additional-services",
  POST_INFANT_DATA: "/ms/manage-order/update-infant-count",
  CREATE_GUEST_INFO: "/ms/manage-order/create-guest",
  UPDATE_GUEST_INFO: "/ms/manage-order/update-guest",

  // CANCEL_PRODUCT_ORDER: '/ms/booking/cancel', // Delete This!
  CANCEL_PRODUCT_ORDER: '/ms/manage-order/cancel',

  ATTRACTIONS: {
    INDEX: '/supplier/activity/index',
    GET: '/supplier/activity/view',
    UPDATE: '/supplier/activity/update',
    CREATE: '/supplier/activity/create',
    DELETE: '/supplier/activity/delete',
    RULES: '/supplier/activity/rules',
  },
  ATTRACTIONS_PRICE: {
    INDEX: '/supplier/activityprice/index',
    CREATE: '/supplier/activityprice/create',
    GET: '/supplier/activityprice/view',
    UPDATE: '/supplier/activityprice/update',
    DELETE: '/supplier/activityprice/delete',
    RULES: '/supplier/activityprice/rules',
  },
  COMMISSION: {
    INDEX: '/supplier/commission/index',
    GET: '/supplier/commission/view',
    CREATE: '/supplier/commission/create',
    UPDATE: '/supplier/commission/update',
    RULES: '/supplier/commission/rules',
  },
  BASE_COMMISSION: {
    INDEX: '/supplier/commission-group/index',
    GET: '/supplier/commission-group/view',
    UPDATE: '/supplier/commission-group/update',
    CREATE: '/supplier/commission-group/create',
    DELETE: '/supplier/commission-group/delete',
    RULES: '/supplier/commission-group/rules',
  },
  CONCEPT: {
    GET: '/supplier/concept/view',
    INDEX: '/supplier/concept/index',
    CONCEPT_RULES: 'supplier/concept/rules',
    UPDATE: '/supplier/concept/update',
    CREATE: '/supplier/concept/create',
  },
  LEAD: {
    GET: '/supplier/lead/view',
    INDEX: '/supplier/lead/index',
    UPDATE: '/supplier/lead/update',
    ASSIGN_TO: '/supplier/lead/assignToMe',
    CREATE: '/supplier/lead/create',
    DELETE: '/supplier/lead/delete',
    RULES: 'supplier/lead/rules',
    CALLBACK: 'supplier/lead/callBack',
    POST_LOG: 'supplier/lead/addLog',
    GET_LOG: 'supplier/lead/log',
  },
  PACKAGE: {
    INDEX: '/supplier/package/index',
    GET: '/supplier/package/view',
    GET_SERIES: '/supplier/packageseries/view',
    INDEX_SERIES: '/supplier/packageseries/index',
    UPDATE: '/supplier/packageseries/update',
    CREATE: '/supplier/packageseries/create',
    GET_CONCEPTS: '/supplier/packageseries/getConcepts',
    CREATE_CONCEPT: '/supplier/packageseries/createConcept',
    DELETE: '/supplier/package/delete',
    DELETE_SERIES: '/supplier/packageseries/delete',
    ADD_HOTEL_TO_SERIES: '/supplier/packageseries/addHotel',
    ADD_ATTRACTION_TO_SERIES: '/supplier/packageseries/addAttractions',
    REMOVE_HOTEL_TO_SERIES: '/supplier/packageseries/removeHotel',
    REMOVE_ATTRACTION_TO_SERIES: '/supplier/packageseries/removeAttraction',
    GET_FLIGHTS_FOR_PACKAGE_SERIES: '/supplier/packageseries/GetAvailableFlights',
    POST_FLIGHTS_TO_PACKAGE_SERIES: '/supplier/packageseries/attachFlights',
  },
  MANAGEMENT: {
    GET_TENANT: '/supplier/tenant/view',
    UPDATE_TENANT: '/supplier/tenant/update',
    GET_SITE: "supplier/site/view",
    UPDATE_SITE: 'supplier/site/update',
    CREATE_AGENCY_WEBSITE:"/supplier/website/createAgencyWebsite",
    // PROBABLY
    CREATE_SITE:"supplier/site/create",
    GET_CONFIG:"/supplier/website/config",
    UPLOAD_IMAGE:"/supplier/website/uploadImage",

  },
  WEBSITE_PRICE_RULES: {
    INDEX: '/supplier/website-price-rule/index',
    UPDATE: '/supplier/website-price-rule/update',
    CREATE: "supplier/website-price-rule/create",
    GET: 'supplier/website-price-rule/view',
    GET_WEBSITE_INDEX:"/supplier/website/index",
    DELETE_WEBSITE: "supplier/website/delete",
    // RULES:"supplier/website-price-rule/rules"
  },
  GROUP: {
    INDEX: '/supplier/group/index',
    GET: '/supplier/group/view',
    UPDATE: '/supplier/group/update',
    CREATE: '/supplier/group/create',
    DELETE: '/supplier/group/delete',
  },
  GROUP_MEMBER: {
    INDEX: '/supplier/group-member/index',
    GET: '/supplier/group-member/view',
    UPDATE: '/supplier/group-member/update',
    CREATE: '/supplier/group-member/create',
    DELETE: '/supplier/group-member/delete',
    POST:"/supplier/group-member/import?group_id="
  },
  DICTIONARY: {
    DROPDOWN: '/supplier/dictionary/dropdown'
  },
  REPORTS: {
    DASHBOARD_TODAY: '/supplier/report/today-data',
    DASHBOARD_LASTX: '/supplier/report/orders-sum-by',
  },
  AUTH: {
    USER_INFO: '/supplier/site/user-info',
    LOGIN: '/supplier/auth/login',
    LOGOUT: '/supplier/auth/logout',
    REFRESH_TOKEN: '/supplier/auth/refresh-token',
  },

};

export default endpoints;